import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiResponseError } from "~/src/Lib/types/api";
import groupBy from "lodash/groupBy";

import {
  RemoteData,
  IRemoteData,
  DataStatus,
} from "~/src/Lib/types/dataTransfer";
import {
  Category,
  CategoryModel,
  Channel,
  ChannelCategoryModel,
  Months,
  SpendCategoryInfo,
  SpendCategoryInfoModel,
  SpendCategoryType,
  SpendLimitModel,
  SpendsModel,
  SpendTransactionSubType,
  SpendTransactionType,
  SubCategory,
  Transaction,
} from "./Model";
import {
  convertArraytoSentence,
  getCurrentMonth,
  getCurrentMonthStartDate,
  getLastThreeMonths,
  getMonths,
} from "~/src/Lib/utils";
import { isEmpty } from "lodash";
import { CategoryType } from "~/src/Components/Spends/MonthlySpendChart";

export type SpendMiscData = {
  category: string;
  value: number;
  avg: number;
};
export type highestSpendsMonthType = {
  value: number;
  month: string;
};


export type TransactionWise = {
  [keys in SpendTransactionType]?: {
    [keys in SpendTransactionSubType]?: {
      monthWiseSpend: { [key: string]: Transaction[] };
      monthWiseSpendMap: number[][];
    }
  }
};

export type AvgCategorySpend = {
  [keys in SpendTransactionType]?: {
    [keys in SpendTransactionSubType]?: {
      [key: string]: number;
    }
  };
};


export type CategoryFilterProps = { [keys in SpendCategoryType]: boolean[] };
export type SpendState = {
  transactionWiseData: TransactionWise;
  avgCategorySpend?: AvgCategorySpend;
  avgCategorySpendLastTwoMonths?: AvgCategorySpend;
  avgCategorySpendSpendsCard?: TAvgCategorySpendSpendsCard;
  currentMonthSpendsCard?: TCurrentMonthSpendsCard;
  categoryWiseSpendsSpendsCard?: TCategoryWiseArrSpendsCard;
  spendLimit: {
    isSet: boolean;
    limit: {};
  };
  getSpendLimit: IRemoteData<SpendLimitModel>;
  setSpendLimit: IRemoteData<SpendLimitModel>;
  categoryFilter: IRemoteData<CategoryFilterProps>;
  spendsData: IRemoteData<SpendsModel>;
  spendType: string;
  categoriesData: CategoryModel[];
  categories: string[];
  monthsOfDataAvailable: string[]
  SpendsCategoryInfoMapped: SpendCategoryInfoModel
};

type TCategorySpendSpendsCard = {
  [keys in SpendCategoryType as string]: number;
};

export type TAvgCategorySpendSpendsCard = {
  UPI: TCategorySpendSpendsCard;
  CC: TCategorySpendSpendsCard;
  DC: TCategorySpendSpendsCard;
  all: TCategorySpendSpendsCard;
};

export type TMonthWiseObjSpendsCard = {
  Name: string;
  YearMonth: string;
  totalAmount: number;
  transactions: {
    Name: string;
    amount: number;
  }[];
};

export type TMonthWiseSpendArrSpendsCard = TMonthWiseObjSpendsCard[][];

export type TSpendsArr = TMonthWiseObjSpendsCard[];

export type TCategoryWiseArrSpendsCard = {
  category: string;
  amount: number;
  label: string;
}[];

export type TCurrentMonthSpendsCard = {
  amount: number;
  end_date: string;
  start_date: string;
  label: string;
  accounts: {
    account: string;
    amount: number;
    channels: {
      channel: string;
      amount: number;
      categories: {
        category: string;
        label: string;
        amount: number;
        sub_categories: {
          sub_category: string;
          label: string;
          amount: number;
        }[];
      }[];
    }[];
  }[];
};

export type TCategoryWiseMapSpendsCard = {
  [keys in SpendCategoryType as string]: {
    label: string;
    amount: number;
  };
};


const initialState: SpendState = {
  transactionWiseData: {} as TransactionWise,
  spendLimit: {
    isSet: false,
    limit: {},
  },
  getSpendLimit: new RemoteData(),
  setSpendLimit: new RemoteData(),
  categoryFilter: new RemoteData(),
  spendsData: new RemoteData(),
  spendType: "all",
  categoriesData: {} as CategoryModel[],
  categories: [],
  monthsOfDataAvailable: [],
  SpendsCategoryInfoMapped: {} as SpendCategoryInfoModel,
};


export const currentMonthforAvgCalc = getCurrentMonth(0);
export const currentMonth = getCurrentMonth(1);
export const months = getLastThreeMonths(parseInt(currentMonth));
export const lastMonths = (numberOfMonths: number) => getMonths(parseInt(currentMonth), numberOfMonths);

const SpendSlice = createSlice({
  name: "spends",
  initialState,
  reducers: {
    setSpendLimtValues(state, action: PayloadAction<SpendLimitModel>) {
      if (Object.keys(action.payload).length > 0)
        state.spendLimit = {
          isSet: action?.payload?.enabled,
          // eslint-disable-next-line @typescript-eslint/ban-types
          limit: action?.payload?.limits || {},
        };
    },
    setIsSpendLimit(state, action: PayloadAction<boolean>) {
      if (action.payload && Object.keys(state.spendLimit.limit).length)
        state.spendLimit.isSet = true;
      if (!action.payload) state.spendLimit.isSet = false;
    },
    getSpendLimit(state) {
      state.getSpendLimit = state.getSpendLimit.setLoading();
    },
    getSpendLimitSuccess(state, action: PayloadAction<SpendLimitModel>) {
      state.getSpendLimit = state.getSpendLimit.setData(action.payload);
      state.getSpendLimit.data = {
        enabled: action?.payload?.enabled,
        customerId: action?.payload?.customerId,
        limits: {
          Payments: action?.payload?.limits?.Payments || "",
          Education: action?.payload?.limits?.Education || "",
          Entertainment: action?.payload?.limits?.Entertainment || "",
          Healthcare: action?.payload?.limits?.Healthcare || "",
          Food: action?.payload?.limits?.Food || "",
          Investment: action?.payload?.limits?.Investment || "",
          FundTransfer: action?.payload?.limits?.FundTransfer || "",
          Others: action?.payload?.limits?.Others || "",
          Travel: action?.payload?.limits?.Travel || "",
          Shopping: action?.payload?.limits?.Shopping || "",
          TotalAmount: action?.payload?.limits?.TotalAmount || "",
        },
      };
    },
    getSpendLimitFail(state, action: PayloadAction<ApiResponseError>) {
      state.getSpendLimit = state.getSpendLimit.setError(action.payload);
    },
    setSpendLimit(state) {
      state.setSpendLimit = state.setSpendLimit.setLoading();
    },
    setSpendLimitFail(state, action: PayloadAction<ApiResponseError>) {
      state.setSpendLimit = state.setSpendLimit.setError(action.payload);
    },
    setSpendLimitSuccess(state) {
      state.setSpendLimit.status = DataStatus.LOADED;
    },
    setCategoryFilter(state, action: PayloadAction<CategoryFilterProps>) {
      state.categoryFilter = state.categoryFilter.setData(action.payload);
      state.categoryFilter.status = DataStatus.LOADED;
    },
    fetchSpendsByCategoryAndMonth(state) {
      state.spendsData = state.spendsData.setLoading()
    },
    fetchSpendsByCategoryAndMonthsSuccess(state, action: PayloadAction<SpendsModel>) {
      state.spendsData = state.spendsData.setData(action.payload);

      const categoriesData = action.payload.data?.categories;
      state.categoriesData = categoriesData

      const allSpends = action.payload.data?.spends.months || [];
      const categories = action.payload.data?.categories
        ? action.payload.data.categories.map(category => category.name)
        : [];
      state.categories = categories

      const SpendsCategoryInfoMapped = updateSpendsCategoryInfo(categoriesData)
      state.SpendsCategoryInfoMapped = SpendsCategoryInfoMapped

      const totalMonths = allSpends.length > 0 ? countMonths(allSpends[allSpends.length - 1].start_date) : 12

      const monthsLength = totalMonths >= 12 ? 12 : (totalMonths < 12 && totalMonths >= 9) ? 9 : (totalMonths < 9 && totalMonths >= 6)
        ? 6 : 3

      // monthsOfDataAvailable for calculating average
      const monthsOfDataAvailable = totalMonths > 0 ? getMonths(parseInt(currentMonth), monthsLength) : getMonths(parseInt(currentMonth), 12);

      // state is used for monthly spends card showing all months of data
      state.monthsOfDataAvailable = totalMonths > 0 ? getMonths(parseInt(currentMonth), totalMonths) : getMonths(parseInt(currentMonth), 12);


      const spends = allSpends?.map((data) => {
        return {
          label: data.label,
          start_date: data.start_date,
          end_date: data.end_date,
          amount: data.amount,
          accounts: data.accounts.map((account) => ({
            account: account.account,
            amount: account.amount,
            channels: account.channels.map((channel) => ({
              channel: channel.channel,
              amount: channel.amount,
              categories: channel.categories.reduce((obj, item) => {
                return {
                  ...obj,
                  [item.category]: item,
                };
              }, {}),
            })),
          })),
        };
      });

      const savings: Transaction[] = [];
      const credits: Transaction[] = [];
      const allData: Transaction[] = [];

      spends.forEach(month => {
        month.accounts.forEach(account => {
          if (account.account === "SAVINGS") {
            savings.push({
              label: month.label,
              start_date: month.start_date,
              end_date: month.end_date,
              amount: account.amount,
              channels: account.channels
            });
          } else if (account.account === "CREDITS") {
            credits.push({
              label: month.label,
              start_date: month.start_date,
              end_date: month.end_date,
              amount: account.amount,
              channels: account.channels
            });
          }

          allData.push({
            label: month.label,
            start_date: month.start_date,
            end_date: month.end_date,
            amount: account.amount,
            account: account.account,
            channels: account.channels
          });
        });
      });

      const savingsMonthWiseSpend = groupBy(savings, (data) =>
        new Date(data?.start_date).toLocaleString("default", {
          month: "numeric",
        })
      );
      const creditMonthWiseSpend = groupBy(credits, (data) =>
        new Date(data?.start_date).toLocaleString("default", {
          month: "numeric",
        })
      );
      const allDataMonthWiseSpends = groupBy(allData, (data) =>
        new Date(data?.start_date).toLocaleString("default", {
          month: "numeric",
        })
      );

      const lastTwoMonths = monthsOfDataAvailable.slice(-3, -1)

      state.avgCategorySpend = {
        all: {
          all: calcCategoryAvg(categories, mergeMonthWiseSpend(allDataMonthWiseSpends), currentMonthforAvgCalc, "all", monthsOfDataAvailable),
        },
        savings: {
          all: calcCategoryAvg(categories, savingsMonthWiseSpend, currentMonthforAvgCalc, "all", monthsOfDataAvailable),
          UPI: calcCategoryAvg(categories, savingsMonthWiseSpend, currentMonthforAvgCalc, "UPI", monthsOfDataAvailable),
          debit: calcCategoryAvg(categories, savingsMonthWiseSpend, currentMonthforAvgCalc, "DC", monthsOfDataAvailable),
          others: calcCategoryAvg(categories, savingsMonthWiseSpend, currentMonthforAvgCalc, "REST", monthsOfDataAvailable),
        },
        credit: {
          all: calcCategoryAvg(categories, creditMonthWiseSpend, currentMonthforAvgCalc, "all", monthsOfDataAvailable),
        }
      };

      state.avgCategorySpendLastTwoMonths = {
        all: {
          all: calcCategoryAvg(categories, mergeMonthWiseSpend(allDataMonthWiseSpends), currentMonthforAvgCalc, "all", lastTwoMonths),
        },
        savings: {
          all: calcCategoryAvg(categories, savingsMonthWiseSpend, currentMonthforAvgCalc, "all", lastTwoMonths),
          UPI: calcCategoryAvg(categories, savingsMonthWiseSpend, currentMonthforAvgCalc, "UPI", lastTwoMonths),
          debit: calcCategoryAvg(categories, savingsMonthWiseSpend, currentMonthforAvgCalc, "DC", lastTwoMonths),
          others: calcCategoryAvg(categories, savingsMonthWiseSpend, currentMonthforAvgCalc, "REST", lastTwoMonths),
        },
        credit: {
          all: calcCategoryAvg(categories, creditMonthWiseSpend, currentMonthforAvgCalc, "all", lastTwoMonths),
        }
      };


      state.transactionWiseData = {
        all: {
          all: {
            monthWiseSpend: mergeMonthWiseSpend(allDataMonthWiseSpends),
            monthWiseSpendMap: monthCategoryMap(allDataMonthWiseSpends, SpendsCategoryInfoMapped, monthsOfDataAvailable),
          },
        },
        savings: {
          all: {
            monthWiseSpend: mergeMonthWiseSpend(savingsMonthWiseSpend),
            monthWiseSpendMap: monthCategoryMap(savingsMonthWiseSpend, SpendsCategoryInfoMapped, monthsOfDataAvailable),
          },
          UPI: {
            monthWiseSpend: filterMonthWiseSpend(savingsMonthWiseSpend, "UPI"),
            monthWiseSpendMap: monthCategoryMap(filterMonthWiseSpend(savingsMonthWiseSpend, "UPI"), SpendsCategoryInfoMapped, monthsOfDataAvailable),
          },
          debit: {
            monthWiseSpend: filterMonthWiseSpend(savingsMonthWiseSpend, "DC"),
            monthWiseSpendMap: monthCategoryMap(filterMonthWiseSpend(savingsMonthWiseSpend, "DC"), SpendsCategoryInfoMapped, monthsOfDataAvailable),
          },
          others: {
            monthWiseSpend: filterMonthWiseSpend(savingsMonthWiseSpend, "REST"),
            monthWiseSpendMap: monthCategoryMap(filterMonthWiseSpend(savingsMonthWiseSpend, "REST"), SpendsCategoryInfoMapped, monthsOfDataAvailable),
          },
        },
        credit: {
          all: {
            monthWiseSpend: mergeMonthWiseSpend(creditMonthWiseSpend),
            monthWiseSpendMap: monthCategoryMap(creditMonthWiseSpend, SpendsCategoryInfoMapped, monthsOfDataAvailable),
          },
        },
      };

      let avgCategorySpend = {} as TAvgCategorySpendSpendsCard;

      allSpends.forEach((month) => {
        month.accounts.forEach((account) => {
          account.channels.forEach((channel) => {
            if (
              !avgCategorySpend[
              channel.channel as keyof TAvgCategorySpendSpendsCard
              ]
            ) {
              const categoryMap = {} as TCategorySpendSpendsCard;
              channel.categories.forEach((category) => {
                if (
                  !categoryMap[
                  category.category as keyof TCategorySpendSpendsCard
                  ]
                ) {
                  const catStr = category.category;
                  categoryMap[catStr as keyof TCategorySpendSpendsCard] =
                    category.amount;
                }
              });
              avgCategorySpend[
                channel.channel as keyof TAvgCategorySpendSpendsCard
              ] = categoryMap;
            } else {
              channel.categories.forEach((category) => {
                if (
                  !avgCategorySpend[
                  channel.channel as keyof TAvgCategorySpendSpendsCard
                  ][category.category as keyof TCategorySpendSpendsCard]
                ) {
                  avgCategorySpend[
                    channel.channel as keyof TAvgCategorySpendSpendsCard
                  ][category.category] = category.amount;
                } else {
                  const existingCategory =
                    avgCategorySpend[
                    channel.channel as keyof TAvgCategorySpendSpendsCard
                    ][category.category];
                  avgCategorySpend[
                    channel.channel as keyof TAvgCategorySpendSpendsCard
                  ][category.category] = existingCategory + category.amount;
                }
              });
            }
          });
        });
      });

      const allMap = {} as TCategorySpendSpendsCard;

      for (const [key, value] of Object.entries(avgCategorySpend)) {
        const categories = value;
        for (const [key, value] of Object.entries(categories)) {
          if (!allMap[key]) {
            allMap[key] = value;
          } else {
            const existingItem = allMap[key];
            allMap[key] = existingItem + value;
          }
        }
      }

      avgCategorySpend = {
        all: allMap,
        UPI: avgCategorySpend.UPI,
        CC: avgCategorySpend.CC,
        DC: avgCategorySpend.DC,
      };

      state.avgCategorySpendSpendsCard = avgCategorySpend;

      const currentMonthDate = getCurrentMonthStartDate()
      const currentMonthSpendsCard = allSpends.find(
        (item) => item.start_date === currentMonthDate
      );

      state.currentMonthSpendsCard = currentMonthSpendsCard;

      let categoryMap = {} as TCategoryWiseMapSpendsCard;

      currentMonthSpendsCard?.accounts.forEach((account) => {
        account.channels.map((channel) => {
          channel.categories.map((category) => {
            if (!categoryMap[category.category]) {
              categoryMap[category.category] = {
                amount: category.amount,
                label: category.label,
              };
            } else {
              let existingCategory = categoryMap[category.category];
              categoryMap[category.category] = {
                ...existingCategory,
                amount: existingCategory.amount + category.amount,
              };
            }
          });
        });
      });

      const categoryArr = [] as TCategoryWiseArrSpendsCard;
      for (const [key, value] of Object.entries(categoryMap)) {
        categoryArr.push({ category: key, ...value });
      }

      categoryArr.sort((a, b) => b.amount - a.amount);
      const getDummyCategoryArr = () => {
        const categoryDummyArr = [] as TCategoryWiseArrSpendsCard
        for (const [key, value] of Object.entries(CategoryType)) {
          if (key !== CategoryType.All) {
            categoryDummyArr.push({ label: value, amount: 0, category: key })
          }
        }
        return categoryDummyArr
      }

      state.categoryWiseSpendsSpendsCard = categoryArr.length ? categoryArr : getDummyCategoryArr();
    },
    fetchSpendsByCategoryAndMonthFail(state, action: PayloadAction<ApiResponseError>) {
      state.spendsData = state.spendsData.setError(action.payload)
    }
  },
});

export const {
  fetchSpendsByCategoryAndMonth,
  fetchSpendsByCategoryAndMonthsSuccess,
  fetchSpendsByCategoryAndMonthFail,
  setSpendLimtValues,
  setIsSpendLimit,
  getSpendLimit,
  getSpendLimitFail,
  getSpendLimitSuccess,
  setSpendLimit,
  setSpendLimitFail,
  setSpendLimitSuccess,
  setCategoryFilter,
} = SpendSlice.actions;
export const spendReducer = SpendSlice.reducer;


export const monthCategoryMap = (spends: { [key: string]: Transaction[] }, SpendsCategoryInfoMapped: SpendCategoryInfoModel, monthsOfDataAvailable: string[]): number[][] => {
  const categoryWiseSpend = SpendsCategoryInfoMapped;
  const months = monthsOfDataAvailable;
  const categories = Object.keys(categoryWiseSpend);
  const updatedCategories = categories.map(category =>
    category === "Fund Transfer" ? "FundTransfer" : category
  );

  const categoryMonthMap: number[][] = new Array(!isEmpty(months) ? months.length : 12).fill(0).map(() => new Array(updatedCategories.length).fill(0));

  months.forEach((monthKey, monthIndex) => {
    const transactions = spends[monthKey];

    transactions && transactions.forEach(transaction => {
      const { channels } = transaction;

      channels.forEach(channel => {
        const transactionCategories = channel.categories;

        updatedCategories.forEach((categoryKey, categoryIndex) => {

          if (transactionCategories[categoryKey]) {
            categoryMonthMap[monthIndex][categoryIndex] += transactionCategories[categoryKey].amount;
          }
        });
      });
    });
  });

  return categoryMonthMap;
};

export const getFilteredData = (
  catFilter: { [key: string]: string[] },
  monthWiseSpend: { [key: string]: Transaction[] }
): { [key: string]: Transaction[] } => {
  if (Object.keys(catFilter).length === 0) return monthWiseSpend;

  const filteredData: { [key: string]: Transaction[] } = {};

  Object.keys(monthWiseSpend).forEach((month) => {
    const filteredMonthData = monthWiseSpend[month].map(transaction => {
      const filteredChannels = transaction.channels.map(channel => {
        const filteredCategories = Object.keys(channel.categories).reduce((acc, categoryKey) => {
          const category = channel.categories[categoryKey];
          const filter = catFilter[category.label] || [];

          const filteredSubCategories = category.sub_categories.filter(subcategory =>
            filter.includes(subcategory.label)
          );
          if (filteredSubCategories.length > 0) {
            acc[categoryKey] = {
              ...category,
              amount: filteredSubCategories.reduce((sum, subcategory) => sum + subcategory.amount, 0),
              sub_categories: mergeSubcategories(filteredSubCategories)
            };
          }
          return acc;
        }, {} as { [key: string]: typeof channel.categories[keyof typeof channel.categories] });

        return {
          ...channel,
          categories: filteredCategories
        };
      });

      return {
        ...transaction,
        channels: filteredChannels
      };
    });

    filteredData[month] = filteredMonthData;
  });

  return filteredData;
};


export const calcHighLowSpends = (
  avgCategorySpend: { [key: string]: number },
  type: string
): SpendMiscData => {
  if (type === "high") {
    const avgSpendData = Object.keys(avgCategorySpend).reduce(
      (prev: SpendMiscData[], curr) => {
        if (
          avgCategorySpend[curr as SpendCategoryType] > prev[0].value
        )
          return [
            {
              category: curr,
              value: avgCategorySpend[curr as SpendCategoryType],
              avg: avgCategorySpend[curr as SpendCategoryType],
            },
          ];
        if (
          avgCategorySpend[curr as SpendCategoryType] > 0 &&
          avgCategorySpend[curr as SpendCategoryType] === prev[0].value
        )
          return [
            ...prev,
            {
              category: curr,
              value: avgCategorySpend[curr as SpendCategoryType],
              avg: avgCategorySpend[curr as SpendCategoryType],
            },
          ];
        return prev;
      },
      [{ category: "", value: 0, avg: 0 }] as SpendMiscData[]
    );
    return {
      category: convertArraytoSentence(avgSpendData),
      value: avgSpendData.length > 0 ? avgSpendData[0].value : 0,
      avg: avgSpendData.length > 0 ? avgSpendData[0].avg : 0,
    };
  }

  //calc lowest
  const avgSpendData = Object.keys(avgCategorySpend).reduce(
    (prev: SpendMiscData[], curr) => {
      if (
        avgCategorySpend[curr as SpendCategoryType] > 0 &&
        avgCategorySpend[curr as SpendCategoryType] < prev[0].value
      )
        return [
          {
            category: curr,
            value: avgCategorySpend[curr as SpendCategoryType],
            avg: avgCategorySpend[curr as SpendCategoryType],
          },
        ];
      if (
        avgCategorySpend[curr as SpendCategoryType] > 0 &&
        avgCategorySpend[curr as SpendCategoryType] === prev[0].value
      )
        return [
          ...prev,
          {
            category: curr,
            value: avgCategorySpend[curr as SpendCategoryType],
            avg: avgCategorySpend[curr as SpendCategoryType],
          },
        ];
      return prev;
    },
    [{ category: "", value: Number.MAX_VALUE, avg: 0 }] as SpendMiscData[]
  );
  return {
    category: convertArraytoSentence(avgSpendData),
    value: avgSpendData.length > 0 ? avgSpendData[0].value : 0,
    avg: avgSpendData.length > 0 ? avgSpendData[0].avg : 0,
  };
}

export type AvgHighSpendsType = { name: string; amount: number }[];
export const calcAvgHighSpends = (
  monthWiseData: { [key: string]: Transaction[] },
  avgCategorySpend: any
): AvgHighSpendsType => {
  const result: AvgHighSpendsType = [];

  if (monthWiseData && monthWiseData[currentMonth] && monthWiseData[currentMonth].length > 0) {
    monthWiseData[currentMonth].forEach((account) => {
      account.channels.forEach((channel) => {
        Object.keys(channel?.categories).forEach((categoryKey) => {
          const category = channel.categories[categoryKey];
          const avgSpend = avgCategorySpend[categoryKey as SpendCategoryType] || 0;

          if (category.amount > avgSpend / 2) {
            result.push({
              name: category.label,
              amount: category.amount,
            });
          }
        });
      });
    });
  }

  return result;
};


export const calcCategoryAvg = (
  categories: string[],
  allMonthWiseSpend: { [key: string]: Transaction[] },
  currentMonth: string,
  filterChannel: string,
  monthsOfDataAvailable: string[]
): { [keys: string]: number } => {
  const spendCategoryObj: { [keys: string]: number } = {};
  categories.forEach(category => spendCategoryObj[category] = 0);

  const validMonths = monthsOfDataAvailable.filter(month =>
    month !== currentMonth
  );

  const avgCategorySpend = validMonths
    .map(month => allMonthWiseSpend[month])
    .flat();
  avgCategorySpend.filter(item => item !== undefined).forEach(transaction => {
    const { channels } = transaction;
    channels.forEach(channel => {
      if (filterChannel === "all" || channel.channel === filterChannel) {
        Object.values(channel.categories).forEach(category => {
          if (spendCategoryObj.hasOwnProperty(category.category)) {
            spendCategoryObj[category.category] += category.amount;
          }
        });
      }
    });
  });

  return spendCategoryObj;
};


export const filterMonthWiseSpend = (
  spends: { [key: string]: Transaction[] },
  channelKey: string
): { [key: string]: Transaction[] } => {
  const filteredData: { [key: string]: Transaction[] } = {};

  Object.keys(spends).forEach(month => {
    const filteredTransactions = spends[month].map(transaction => {
      const amount = 0
      const filteredChannels = transaction.channels.filter(channel => {
        return channel.channel === channelKey
      })

      return {
        ...transaction,
        channels: filteredChannels,
        amount: amount + (filteredChannels.length > 0 ? filteredChannels[0].amount : 0)
      };
    }).filter(transaction => transaction.channels.length > 0);

    filteredData[month] = filteredTransactions;
  });

  return filteredData;
}

const mergeSubcategories = (subcategories: any[]) => {
  const merged = subcategories.reduce((acc, subcat) => {
    const existing = acc.find((s: any) => s.sub_category === subcat.sub_category);
    if (existing) {
      existing.amount += subcat.amount;
    } else {
      acc.push({ ...subcat });
    }
    return acc;
  }, [] as any[]);
  return merged;
};

const mergeMonthWiseSpend = (allMonthWiseSpend: { [key: string]: Transaction[] }): { [key: string]: Transaction[] } => {
  const allMonthWiseSpendCopy: { [key: string]: Transaction[] } = JSON.parse(JSON.stringify(allMonthWiseSpend));

  const mergedData: { [key: string]: Transaction[] } = {};

  Object.keys(allMonthWiseSpendCopy).forEach((month) => {
    const mergedChannels: { [key: string]: Channel } = {};

    allMonthWiseSpendCopy[month].forEach((item: Transaction) => {
      item.channels.forEach((channel: Channel) => {
        if (mergedChannels[channel.channel]) {
          // Channel already exists, merge amounts and categories
          mergedChannels[channel.channel].amount += channel.amount;
          mergedChannels[channel.channel].categories = mergeCategories(
            mergedChannels[channel.channel].categories,
            channel.categories
          );
        } else {
          // Channel does not exist, create new channel
          mergedChannels[channel.channel] = {
            channel: channel.channel,
            amount: channel.amount,
            categories: { ...channel.categories }
          };
        }
      });
    });

    // Create a single merged channel with aggregated amounts and merged categories
    const aggregatedChannel: Channel = {
      channel: Object.keys(mergedChannels).join(''),
      amount: Object.values(mergedChannels).reduce((sum, channel) => sum + channel.amount, 0),
      categories: mergeCategoriesAcrossChannels(Object.values(mergedChannels).map(channel => channel.categories))
    };

    const mergedTransaction: Transaction = {
      label: allMonthWiseSpendCopy[month][0].label,
      start_date: allMonthWiseSpendCopy[month][0].start_date,
      end_date: allMonthWiseSpendCopy[month][0].end_date,
      amount: allMonthWiseSpendCopy[month].reduce((sum, item) => sum + item.amount, 0),
      account: allMonthWiseSpendCopy[month].map((item) => item.account).filter(Boolean).join(''),
      channels: [aggregatedChannel],
    };

    mergedData[month] = [mergedTransaction];
  });

  return mergedData;
};

const mergeCategoriesAcrossChannels = (categoriesList: { [key: string]: Category }[]): { [key: string]: Category } => {
  const mergedCategories: { [key: string]: Category } = {};

  categoriesList.forEach(categories => {
    Object.values(categories).forEach(category => {
      if (!mergedCategories[category.category]) {
        mergedCategories[category.category] = { ...category };
      } else {
        mergedCategories[category.category].amount += category.amount;
        mergedCategories[category.category].sub_categories = mergeSubCategories(
          mergedCategories[category.category].sub_categories,
          category.sub_categories
        );
      }
    });
  });

  return mergedCategories;
};

const mergeCategories = (categories1: { [key: string]: Category }, categories2: { [key: string]: Category }): { [key: string]: Category } => {
  const categoryMap: { [key: string]: Category } = { ...categories1 };

  Object.values(categories2).forEach(category => {
    if (!categoryMap[category.category]) {
      categoryMap[category.category] = { ...category };
    } else {
      const existingCategory = categoryMap[category.category];
      categoryMap[category.category] = {
        ...existingCategory,
        amount: existingCategory.amount + category.amount,
        sub_categories: mergeSubCategories(existingCategory.sub_categories, category.sub_categories),
      };
    }
  });

  return categoryMap;
};

const mergeSubCategories = (subcategories1: SubCategory[], subcategories2: SubCategory[]): SubCategory[] => {
  const subcategoryMap: { [key: string]: SubCategory } = {};

  subcategories1.concat(subcategories2).forEach(subcategory => {
    if (!subcategoryMap[subcategory.sub_category]) {
      subcategoryMap[subcategory.sub_category] = { ...subcategory };
    } else {
      const existingSubcategory = subcategoryMap[subcategory.sub_category];
      subcategoryMap[subcategory.sub_category] = {
        ...existingSubcategory,
        amount: existingSubcategory.amount + subcategory.amount,
      };
    }
  });

  return Object.values(subcategoryMap);
};

export const updateSpendsCategoryInfo = (
  categoriesData: CategoryModel[]
): SpendCategoryInfoModel => {
  const SpendsCategoryInfoMapped = { ...SpendCategoryInfo };
  const CatergoriesDataNew = categoriesData && categoriesData.length > 0 ? JSON.parse(JSON.stringify(categoriesData)) : [];

  if (CatergoriesDataNew.length > 0)
    CatergoriesDataNew.forEach((category: CategoryModel) => {
      const spendCategory = SpendsCategoryInfoMapped[category.label as SpendCategoryType];

      if (spendCategory) {
        category.subcategories.forEach((newSubcategory) => {
          if (!spendCategory.subcategory.includes(newSubcategory.label)) {
            spendCategory.subcategory.push(newSubcategory.label);
          }
        });
      }
    });

  return SpendsCategoryInfoMapped;
};


export const fillMissingMonths = (months: string[], monthWiseSpends: { [key: string]: Transaction[] }, spendSubType: string) => {

  const updatedSpendsData = { ...monthWiseSpends }
  // Create the default categories using mapping
  const categoryInfo: SpendCategoryInfoModel = { ...SpendCategoryInfo };
  const categoryNames = Object.keys(categoryInfo) as Array<keyof SpendCategoryInfoModel>;
  const defaultCategoryNames = ["Healthcare", "Investment", "Shopping", "Fund Transfer"];

  const defaultCategories: { [key: string]: ChannelCategoryModel } = Object.fromEntries(
    categoryNames.map(category => {
      const subcategories = categoryInfo[category]?.subcategory?.map((subcategory: string) => {
        return {
          amount: 0,
          label: subcategory,
          sub_category: subcategory,
        } as SubCategory;
      }) || [];

      return [
        category,
        {
          category,
          label: category,
          amount: 0,
          alwaysShow: defaultCategoryNames.includes(category) ? true : false,
          sub_categories: subcategories
        }
      ];
    })
  );

  const currentYear = new Date().getFullYear();

  months.forEach(month => {
    const hasValues = monthWiseSpends[month]?.length > 0
    if (!monthWiseSpends[month] || !hasValues) {
      updatedSpendsData[month] = [
        {
          "label": `${Months[Number(month) - 1]} spends`,
          "start_date": `${currentYear}-${month.padStart(2, '0')}-01`,
          "end_date": `${currentYear}-${month.padStart(2, '0')}-${new Date(currentYear, parseInt(month), 0).getDate()}`,
          "amount": 0,
          "account": "",
          "channels": [
            {
              "channel": spendSubType,
              "amount": 0,
              "categories": defaultCategories
            }
          ]
        }
      ];
    }
  });

  return updatedSpendsData
};

export const countMonths = (dateString: string) => {
  const startDate = new Date(dateString);
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();

  const startYear = startDate.getFullYear();
  const startMonth = startDate.getMonth();

  const months = (currentYear - startYear) * 12 + (currentMonth - startMonth);

  return months + 1;
}

export default SpendSlice.reducer;

