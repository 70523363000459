import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiResponseError } from "~/src/Lib/types/api";
import { IRemoteData, RemoteData } from "~/src/Lib/types/dataTransfer";
import { OnboardingModel } from "./Model";
import { SLICE_NAME } from "./Selectors";
export type OnBoardingState = {
  onboarding: IRemoteData<OnboardingModel>;
};
const initialState: OnBoardingState = {
  onboarding: new RemoteData(),
};
const OnboardingSlice = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchOnboarding(state) {
      state.onboarding = state.onboarding.setLoading();
    },
    fetchOnboardingSuccess(state, action: PayloadAction<OnboardingModel>) {
      state.onboarding = state.onboarding.setData(action.payload);
    },
    fetchOnboardingFail(state, action: PayloadAction<ApiResponseError>) {
      state.onboarding = state.onboarding.setError(action.payload);
    },
  },
});

export const { fetchOnboarding, fetchOnboardingSuccess, fetchOnboardingFail } =
  OnboardingSlice.actions;
export const OnboardingReducer = OnboardingSlice.reducer;
