import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ApiResponseError } from "~/src/Lib/types/api";
import {
  fetchOnboarding,
  fetchOnboardingFail,
  fetchOnboardingSuccess,
} from "../Reducer";
import { OnboardingMapper, onboardingPayload } from "../Model";
import { OnBoardingState } from "../Reducer";
import { WebHttpRequestOptions } from "@am92/web-http";
import { asHttp } from "~/src/Configurations/WebHttp";
import { AS_API_DOMAIN } from "~/src/Configurations/env";

type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  OnBoardingState,
  string,
  Action<string>
>;

const UPDATE_STATUS = "user/saveVideoStatus";
const channelId = sessionStorage.getItem("channelId") || "";

export const updateOnBoardingThunk =
  ({
    customerId,
    watched,
  }: onboardingPayload): AppThunk<Promise<any | ApiResponseError>> =>
  async (dispatch: ThunkDispatch<OnBoardingState, string, Action<string>>) => {
    dispatch(fetchOnboarding());
    try {
      const options: WebHttpRequestOptions = {
        url: `${AS_API_DOMAIN}/${UPDATE_STATUS}`,
        method: "POST",
        data: {
          customerId,
          watched,
          channelId,
        },
      };

      const response = await asHttp.request(options);
      const { data: body } = response;
      const { data } = body;
      const mappedData = await new OnboardingMapper().toModel(data);

      dispatch(fetchOnboardingSuccess(mappedData));
      return mappedData;
    } catch (error) {
      dispatch(fetchOnboardingFail(error as ApiResponseError));
      throw error;
    }
  };
