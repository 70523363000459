import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "notification";

export const getNotificationsdata = createSelector(
  (state: any) => state[SLICE_NAME].notifications,
  (notifications) => notifications
);

export const getNotificationsSet = createSelector(
  (state: any) => state[SLICE_NAME].notificationsSet,
  (notificationsSet) => notificationsSet
);
