import React, { Component } from "react";
import { DsButton, DsTypography, DsRemixIcon, DsButtonProps, DsRemixIconProps } from "@am92/react-design-system";

type ErrorButtonProps = {
  leftIcon?: DsRemixIconProps["className"];
  onClick?: () => void;
  children: string;
} & DsButtonProps;
class ErrorButton extends Component<ErrorButtonProps> {
  render() {
    const { leftIcon, onClick, children, ...rest } = this.props;

    return (
      <DsButton
        color='secondary'
        variant='contained'
        sx={{
          color: 'var(--ds-colour-actionSecondary)',
          backgroundColor: 'var(--ds-colour-surfaceBackground)',
          width: '199px',
        }}
        onClick={onClick}
      // {...rest}
      >
        <DsTypography
          sx={{
            display: 'flex',
            p: 'var(--ds-spacing-deepFreeze) var(--ds-spacing-glacial)',
            alignItems: 'center',
          }}
        >
          {leftIcon && (
            <DsRemixIcon
              className={leftIcon as DsRemixIconProps["className"]}
              fontSize='bitterCold'
              sx={{ mr: 'var(--ds-spacing-quickFreeze)' }}
            />
          )}
          {children}
        </DsTypography>
      </DsButton>
    );
  }
}

export { ErrorButton };
