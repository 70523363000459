import { asHttp } from "~/src/Configurations/WebHttp";
import { getActivityAction } from "../Actions";
import serviceActionCreator from "~/src/Redux/serviceActionCreatorWithTokenRotation";
import { AS_API_DOMAIN } from "~/src/Configurations/env";

export type ActivityData = {
  page: string;
  action: string;
};

async function getActivity(reqData: ActivityData) {
  const options = {
    url: `${AS_API_DOMAIN}/activity`,
    method: "POST",
    data: reqData,
  };

  const response = await asHttp.request(options);
  const { data: body } = response;
  const { data } = body;

  return data;
}

const getActivityServiceAction = serviceActionCreator(
  getActivityAction,
  getActivity
);

export default getActivityServiceAction;
