import SomethingWentWrongComponent from "~/src/Pages/SomethingWentWrong.page";
import React, { Component, ErrorInfo } from "react";

export class ApplicationErrorBoundary extends Component<
  any,
  { hasError: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any): { hasError: boolean } {
    console.group("ApplicationErrorBoundary");
    console.error(error);
    console.groupEnd();
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.group("ApplicationErrorBoundary ErrorInfo");
    console.error(error);
    console.error(errorInfo);
    console.groupEnd();
  }

  render() {
    if (this.state.hasError) {
      return <SomethingWentWrongComponent />;
    }
    return this.props.children;
  }
}
