import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "~/src/Configurations/AppStore";

export const SLICE_NAME = "spends";

const categoryFiltersSelector = (state: RootState) =>
  state[SLICE_NAME].categoryFilter;
const spendLimitSelector = (state: RootState) => state[SLICE_NAME].spendLimit;
const getSpendLimitSelector = (state: RootState) =>
  state[SLICE_NAME].getSpendLimit;
const spendTypeSelector = (state: RootState) => state[SLICE_NAME].spendType;
const transactionWiseDataSelector = (state: RootState) =>
  state[SLICE_NAME].transactionWiseData;
const avgCategorySpendSelector = (state: RootState) =>
  state[SLICE_NAME].avgCategorySpend;
const spendsDataSelector = (state: RootState) =>
  state[SLICE_NAME].spendsData;
const categoriesDataSelector = (state: RootState) =>
  state[SLICE_NAME].categoriesData;
const categoriesSelector = (state: RootState) =>
  state[SLICE_NAME].categories;
const avgCategorySpendSpendsCardSelector = (state: RootState) =>
  state[SLICE_NAME].avgCategorySpendSpendsCard;
// const monthWiseSpendSpendsCardSelector = (state: RootState) =>
//   state[SLICE_NAME].monthWiseSpendSpendsCard;
const currentMonthSpendsCardSelector = (state: RootState) =>
  state[SLICE_NAME].currentMonthSpendsCard;
const categoryWiseSpendsSpendsCardSelector = (state: RootState) =>
  state[SLICE_NAME].categoryWiseSpendsSpendsCard;
const monthsOfDataAvailableSelector = (state: RootState) =>
  state[SLICE_NAME].monthsOfDataAvailable;
const SpendsCategoryInfoMappedSelector = (state: RootState) =>
  state[SLICE_NAME].SpendsCategoryInfoMapped;
const avgCategoryTwoMonthsSpendSelector = (state: RootState) =>
  state[SLICE_NAME].avgCategorySpendLastTwoMonths;


export const getCategoryFilters = createSelector(
  categoryFiltersSelector,
  (journey) => journey
);

export const getSpendLimit = createSelector(
  spendLimitSelector,
  (journey) => journey
);

export const fetchGetSpendLimit = createSelector(
  getSpendLimitSelector,
  (journey) => journey
);

export const getSpendType = createSelector(
  spendTypeSelector,
  (journey) => journey
);

export const getTransactionWiseData = createSelector(
  transactionWiseDataSelector,
  (journey) => journey
);

export const getAvgCategorySpend = createSelector(
  avgCategorySpendSelector,
  (journey) => journey
);

export const getsSpendsData = createSelector(
  spendsDataSelector,
  (journey) => journey
);

export const getCategoriesData = createSelector(
  categoriesDataSelector,
  (journey) => journey
);

export const getCategories = createSelector(
  categoriesSelector,
  (journey) => journey
);

export const getAvgCategorySpendsSpendsCard = createSelector(
  avgCategorySpendSpendsCardSelector,
  (journey) => journey
);

// export const getMonthWiseSpendsSpendsCard = createSelector(
//   monthWiseSpendSpendsCardSelector,
//   (journey) => journey
// );

export const getCurrentMonthSpendsCard = createSelector(
  currentMonthSpendsCardSelector,
  (journey) => journey
);

export const getcategoryWiseSpendsSpendsCard = createSelector(
  categoryWiseSpendsSpendsCardSelector,
  (journey) => journey
);

export const getNoOfMonths = createSelector(
  monthsOfDataAvailableSelector,
  (journey) => journey
);

export const getSpendsCategoryInfoMapped = createSelector(
  SpendsCategoryInfoMappedSelector,
  (journey) => journey
);

export const getLastTwoMonthsAverageSpends = createSelector(
  avgCategoryTwoMonthsSpendSelector,
  (journey) => journey
);


