import React, { CSSProperties, SyntheticEvent } from "react";
import ReactPlayer from "react-player";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { getOnBoardingThunk } from "~/src/Redux/Onboarding/Services/getOnboarding";
import { updateOnBoardingThunk } from "~/src/Redux/Onboarding/Services/updateOnBoarding";
import { getOnboarding } from "~/src/Redux/Onboarding/Selectors";
import { OnBoardingState } from "~/src/Redux/Onboarding/Reducer";
import { RootState } from "~/src/Configurations/AppStore";
import {
  DsAvatar,
  DsBox,
  DsRemixIcon,
  DsTypography,
  keyframes,
} from "@am92/react-design-system";
import { DataStatus } from "~/src/Lib/types/dataTransfer";
import { getCustomerSelector } from "~/src/Redux/Customer/Customer.selector";
import { ICustomerData } from "~/src/Configurations/getAppRouter";
import { AuthState } from "~/src/Redux/Auth/Reducer";

interface OnboardingProps {
  playerRef?: React.RefObject<ReactPlayer>;
  onboarding: OnBoardingState["onboarding"];
  AuthData?: AuthState["AuthData"];
  customer?: ICustomerData;
  actions: {
    getOnBoardingData: (customerId: string) => void;
    updateOnBoardingThunk: (customerId: string, watched: string) => void;
  };
}

interface OnboardingState {
  playing: boolean;
  mute: boolean;
  control: boolean;
  parentwidth: string;
  show: boolean;
}

class Onboarding extends React.Component<OnboardingProps, OnboardingState> {
  myStyles: CSSProperties = {
    position: "fixed",
  };

  spin = keyframes`
    0% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
    100% {
      transform: scale(1.4);
      -webkit-transform: scale(1.4);
    }
  `;

  spinAnimation = `${this.spin} infinite 2s linear`;

  playerRef = React.createRef<ReactPlayer>();
  containerRef = React.createRef<HTMLDivElement>();
  isLargerThan1280 = window?.matchMedia("(min-width: 800px)").matches;

  constructor(props: OnboardingProps) {
    super(props);

    this.state = {
      playing: true,
      mute: true,
      control: false,
      parentwidth: "60%",
      show: false,
    };
  }

  componentDidMount() {
    this.checkPlayerStatus();
    this.startOnboarding();

    setTimeout(() => {
      this.setState({ show: true })
    }, 15000)
  }

  componentDidUpdate(prevProps: OnboardingProps) {
    const { onboarding, AuthData, customer } = this.props;

    if (prevProps.onboarding?.status !== onboarding?.status) {
      this.checkPlayerStatus();
    }

    // if (AuthData && prevProps.AuthData?.status !== AuthData.status || prevProps.customer !== customer) {
    //   this.startOnboarding();
    // }
  }

  checkPlayerStatus() {
    const { playerRef, onboarding } = this.props;
    if (playerRef?.current && onboarding?.status === DataStatus.LOADED) {
      this.setState((prevState) => ({ playing: !prevState.playing }));
    }
  }

  async startOnboarding() {
    const { customer, actions } = this.props;
    if (customer?.customerId) {
      setTimeout(async () => {
        await actions?.getOnBoardingData(customer?.customerId);
      }, 3000);
    }
  }

  handleExpandClick = (e: SyntheticEvent<EventTarget>) => {
    e.stopPropagation();
    this.myStyles.position = "initial";
    this.setState({
      parentwidth: "100%",
      mute: false,
      control: true,
    });
    const appElement = document.getElementById("app");
    if (appElement) {
      appElement.style.display = "none";
    }
  };

  closeVideoCall = async () => {
    const { customer, actions } = this.props;
    if (customer?.customerId) {
      await actions?.updateOnBoardingThunk(
        customer?.customerId,
        "home_splash_21"
      );
    }
  };

  handleOnClick = (e: SyntheticEvent<EventTarget>) => {
    this.handleExpandClick(e);
  };

  handlePlayerState = (playerState: {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
  }) => {
    if (playerState.played >= 1) {
      this.hideVideo();
      this.setState({ show: false });
    }
  };

  hideVideo = () => {
    const playerContainer = document.getElementById("player-container");
    const appElement = document.getElementById("app");
    /** have better way of hiding /removing video element */
    if (playerContainer && appElement) {
      // playerContainer.remove();
      playerContainer.style.display = "none";
      appElement.removeAttribute("style");
      // setTimeout(() => {
      //   appElement.removeAttribute("style");
      //   this.setState({ show: false });
      // }, 100);
      this.closeVideoCall();
    }
  };

  handleCloseClick = (e: SyntheticEvent<EventTarget>) => {
    e.stopPropagation();
    this.hideVideo();
  };

  renderPlayer = () => {
    const { parentwidth, control, playing, mute } = this.state;

    return (
      <DsBox
        id="onboarding"
        maxWidth={parentwidth}
        width={parentwidth}
        height={parentwidth}
        bgcolor="white"
      >
        <DsBox
          sx={
            this.isLargerThan1280
              ? { ...this.myStyles, left: "30%" }
              : { left: "15px", ...this.myStyles }
          }
          bottom={this.isLargerThan1280 ? "5%" : "1%"}
          width={[parentwidth, parentwidth, `${control ? "60vh" : "15%"}`]}
          height={"50%"}
          border="1px"
          borderRadius="var(--ds-spacing--glacial)"
        >
          <DsBox position={"relative"} onClick={(e) => this.handleOnClick(e)}>
            <ReactPlayer
              ref={this.playerRef}
              config={{
                file: {
                  attributes: {
                    onContextMenu: (e: SyntheticEvent<EventTarget>) =>
                      e.preventDefault(),
                    controlsList:
                      "nodownload noplaybackrate nopictureinpicture",
                    disablePictureInPicture: true,
                  },
                },
              }}
              className="react-player"
              id="react-player"
              url={process.env.ONBOARDING_VIDEO}
              playing={playing}
              width="100%"
              height="100%"
              controls={control}
              autoPlay
              volume={1}
              muted={mute}
              stopOnUnmount
              pip={false}
              onProgress={(state) => {
                this.handlePlayerState(state);
              }}
            ></ReactPlayer>
            <DsBox
              position={"absolute"}
              top="1px"
              right="1px"
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <DsTypography
                variant="bodyRegularSmall"
                sx={{
                  cursor: "pointer",
                  color: "var(--ds-colour-actionSecondary)",
                }}
                textTransform="uppercase"
                onClick={this.handleCloseClick}
              >
                Skip
              </DsTypography>
              {!control ? (
                <DsAvatar
                  onClick={(e) => this.handleExpandClick(e)}
                  sx={{
                    color: "var(--ds-colour-typoPrimary)",
                    animation: this.spinAnimation,
                    cursor: "pointer",
                    size: "var(--ds-spacing-quickFreeze)",
                    background: "transparent",
                  }}
                >
                  <DsRemixIcon className='ri-fullscreen-line'
                    sx={{
                      fontSize: '12px!important'
                    }}
                  />
                </DsAvatar>
              ) : null}
            </DsBox>
          </DsBox>
        </DsBox>
      </DsBox>
    );
  };

  render() {
    const { parentwidth, show } = this.state;
    const { onboarding } = this.props;

    const container = document.getElementById("onboarding");
    if (
      container &&
      onboarding.status === DataStatus.LOADED &&
      !onboarding.data?.watchedVideos &&
      process.env.ONBOARDING_VIDEO
    ) {
      return ReactDOM.createPortal(
        <>
          {show && (
            <DsBox
              width={parentwidth}
              minHeight={parentwidth}
              id="player-container"
            >
              <DsBox
                display="flex"
                width="100%"
                height="100%"
                justifyContent={
                  !this.isLargerThan1280 ? "flex-start" : "center"
                }
              >
                {this.renderPlayer()}
              </DsBox>
            </DsBox>
          )}
        </>,
        container
      );
    }
    return null;
  }
}

const mapStateToProps = (
  state: RootState & {
    onboarding: OnboardingState;
  }
) => {
  const onboarding = getOnboarding(state);
  const { user } = getCustomerSelector(state);
  return {
    onboarding,
    AuthData: state.auth,
    customer: user,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  actions: {
    getOnBoardingData: (customerId: string) =>
      dispatch(getOnBoardingThunk(customerId)),
    updateOnBoardingThunk: (customerId: string, watched: string) =>
      dispatch(updateOnBoardingThunk({ customerId, watched })),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
