import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "onboarding";

const getOnboardingData = (state: any) => state[SLICE_NAME].onboarding;

export const getOnboarding = createSelector(getOnboardingData, (journey) => {
  return journey;
});

const updateOnboardingData = (state: any) => state[SLICE_NAME].onboarding;

export const updateOnboarding = createSelector(
  updateOnboardingData,
  (journey) => {
    return journey;
  }
);
