import { ApiResponseError } from "./api";

export enum DataStatus {
  NOT_LOADED = "NOT_LOADED",
  LOADING = "LOADING",
  LOADED = "LOADED",
  ERRORED = "ERRORED",
}

export interface TransferData<T> {
  status: DataStatus;
  data: T;
  error?: ApiResponseError;
}

export interface IRemoteData<T> {
  status: DataStatus;
  data?: T;
  error?: ApiResponseError;
  setLoading(): IRemoteData<T>;
  setData(data: T): IRemoteData<T>;
  setError(error: ApiResponseError): IRemoteData<T>;
}

export class RemoteData<T> implements IRemoteData<T> {
  status: DataStatus;
  data?: T;
  error?: ApiResponseError;
  constructor(status?: DataStatus, data?: T, error?: ApiResponseError) {
    this.status = status || DataStatus.NOT_LOADED;
    this.data = data;
    this.error = error;
  }
  setError(error: ApiResponseError): IRemoteData<T> {
    // this.status = DataStatus.ERRORED;
    // this.error = error;
    return new RemoteData(DataStatus.ERRORED, this.data, error);
  }
  setLoading(): IRemoteData<T> {
    // this.status = DataStatus.LOADING;
    // this.error = undefined;
    // return this;
    return new RemoteData(DataStatus.LOADING, this.data, undefined);
  }
  setData(data: T): IRemoteData<T> {
    // this.data = data;
    // this.status = DataStatus.LOADED;
    // this.error = undefined;
    // return this;
    return new RemoteData(DataStatus.LOADED, data, undefined);
  }
}
