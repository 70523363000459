import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiResponseError } from "~/src/Lib/types/api";
import { RemoteData, IRemoteData } from "~/src/Lib/types/dataTransfer";
import { ActivityModel } from "./Model";

export type ActivityState = {
  activity: IRemoteData<ActivityModel>;
};
const initialState: ActivityState = {
  activity: new RemoteData(),
};

const ActivitySlice = createSlice({
  name: "sso",
  initialState,
  reducers: {
    fetchActivity(state) {
      state.activity = state.activity.setLoading();
    },
    fetchActivitySuccess(state, action: PayloadAction<ActivityModel>) {
      state.activity = state.activity.setData(action.payload);
    },
    fetchActivityFail(state, action: PayloadAction<ApiResponseError>) {
      state.activity = state.activity.setError(action.payload);
    },
  },
});

export const { fetchActivity, fetchActivityFail, fetchActivitySuccess } =
  ActivitySlice.actions;

export const ActivityReducer = ActivitySlice.reducer;
