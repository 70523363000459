import React, { Component, ReactElement } from 'react'
import ErrorLayout from '../Components/ErrorLayout'
import { DsBox, DsImage } from '@am92/react-design-system'
import { APPLICATION_DOWN } from '../Constants/ASSET_MAP'
import { handleGAPush } from '../GoogleAnalytics/googleAnalytics.main'
import { ERROR_PAGE } from "~/src/GoogleAnalytics/googleAnalytics.model";
import { ThunkDispatch } from '@reduxjs/toolkit'
import getActivityServiceAction from '../Redux/Activity/Services/getActivity.Service'
import { connect } from 'react-redux'
import { ERROR_MSG, PAGES } from '../Redux/Activity/Model'
import withRouter from '../Lib/withRouter'
import { title } from 'node:process'

interface SomethingWentWrongComponentProps {
  error?: string;
  encrCustomerId: string;
  title?: string;
  disablePFM?: boolean;
  description?: string | ReactElement;
  actions: {
    registerActivity: (page: string, action: string) => void;
  }
};

class SomethingWentWrongComponent extends Component<SomethingWentWrongComponentProps> {
  componentDidMount(): void {
    const { encrCustomerId, actions: { registerActivity }, error = ERROR_MSG } = this.props
    handleGAPush({ ...ERROR_PAGE.SORRY_FOR_INCOVINIENCE_PAGE_ONLOAD, user_id: encrCustomerId })
    handleGAPush({ ...ERROR_PAGE.SORRY_FOR_INCOVINIENCE_PAGE_ONLOAD_VPV, user_id: encrCustomerId })

    registerActivity(PAGES.HOME, error);
  }
  render() {
    const { title, description, disablePFM } = this.props
    return (
      <DsBox style={disablePFM ? {
        position: 'relative',
        marginTop: '-110px',
        background: 'white',
        zIndex: 9,
      } : {}}>
        <ErrorLayout
          errorImage={
            <DsImage srcSet={APPLICATION_DOWN} style={{ width: '193px', height: 'auto' }} />
          }
          title={title ?? 'Sorry for the inconvenience'}
          description={description ?? 'We are experiencing some difficulties. Please try again after some time.'}
        />
      </DsBox>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  actions: {
    registerActivity: (page: string, action: string) =>
      dispatch(getActivityServiceAction({ page, action })),
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SomethingWentWrongComponent));
;
