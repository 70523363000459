import { createSelector } from "@reduxjs/toolkit";
import { AuthState } from "./Reducer";

export const SLICE_NAME = "auth";

const accessTokenSelect = (state: any) => state[SLICE_NAME].accessToken;
const refreshTokenSelect = (state: any) => state[SLICE_NAME].refreshToken;
const AuthDataSelect = (state: any) => state[SLICE_NAME];
const saveTokenSelect = (state: any) => state[SLICE_NAME].saveToken;
const saveSessionIdSelect = (state: any) => state[SLICE_NAME].saveSessionId;

export const getAccessTokenSelector = createSelector(
  accessTokenSelect,
  (accessToken) => accessToken
);

export const getRefreshTokenSelector = createSelector(
  refreshTokenSelect,
  (refreshToken) => refreshToken
);

export const saveTokenSelector = createSelector(
  saveTokenSelect,
  (saveToken) => saveToken
);

export const saveSessionIdSelector = createSelector(
  saveSessionIdSelect,
  (saveSessionId) => saveSessionId
);

export const getAuthSelector = createSelector(
  AuthDataSelect,
  (AuthData: AuthState) => AuthData
);
