import React, { ReactElement, useEffect } from "react";
import ErrorLayout from "../Components/ErrorLayout";
import { NO_INVESTMENT, SESSION_EXPIRED, SORRY_FOR_INCONVENIENCE, ACCOUNT_ERROR } from '../Constants/ASSET_MAP'
import { ErrorButton } from "~/src/Components/ErrorButton";
import { isSessionStorage } from "~/src/Lib/utils";
import SomethingWentWrongComponent from "./SomethingWentWrong.page";

import { DsButton, DsImage, DsLink, DsRemixIcon, DsStack, DsTypography } from "@am92/react-design-system";
import withRouter, { IWithRouterProps } from '~/src/Lib/withRouter'
import APP_ROUTES from "../Constants/APP_ROUTES";
import { useNavigate } from "react-router-dom";
import { handleGAPush } from "../GoogleAnalytics/googleAnalytics.main";
import { ERROR_PAGE } from "~/src/GoogleAnalytics/googleAnalytics.model";


const incRetryCount = () => {
  if (!isSessionStorage("retry")) sessionStorage.setItem("retry", "0");
  else {
    let count = parseInt(sessionStorage.getItem("retry") || "0");
    count = (count + 1) % 4;
    sessionStorage.setItem("retry", count.toString());
  }
};

type TimeoutErrorComponent = {
  retryData?: () => void;
  encrCustomerId?: string;
}

interface CommonErrorProps extends IWithRouterProps {
  isRetryMaxed?: boolean;
  isTimeout?: boolean;
  retryData?: () => void;
  isNeutralError?: boolean;
  encrCustomerId?: string;
  errorMsg?: string;
  disablePFM?: boolean;
  title?: string;
  description?: string | ReactElement;
};
class CommonErrorComponent extends React.Component<CommonErrorProps> {
  render() {
    const { isRetryMaxed, isTimeout, retryData, navigate, isNeutralError, encrCustomerId, errorMsg, title, description, disablePFM } = this.props
    return (
      <>
        {isRetryMaxed ? (
          <SomethingWentWrongComponent encrCustomerId={encrCustomerId} title={title} description={description} disablePFM={disablePFM} />
        ) : (
          <>
            {isTimeout ? (
              <TimeoutErrorComponent retryData={retryData} encrCustomerId={encrCustomerId} />
            ) : (
              <ErrorLayout
                errorImage={
                  <DsImage
                    style={{ marginTop: "max(16vh, 4rem)", width: "192px" }}
                    srcSet={SORRY_FOR_INCONVENIENCE}
                  />
                }
                isNeutralError={isNeutralError}
                errorMsg={errorMsg}
                title="Sorry for the inconvenience"
                description="We are experiencing some difficulties. Please try again after some time."
                bottomStickerRender={
                  <ErrorButton
                    leftIcon='ri-refresh-line'
                    onClick={() => {
                      retryData ? retryData() : navigate(0);
                      incRetryCount();
                    }}
                  >
                    Try Again
                  </ErrorButton>
                }
              ></ErrorLayout>
            )}
          </>
        )
        }
      </>
    );
  }
};

export const FDRDErrorComponent = () => {
  const navigate = useNavigate();
  return (
    <ErrorLayout
      errorImage={
        <DsImage style={{ marginTop: "max(16vh, 4rem)", width: "149px" }} srcSet={SORRY_FOR_INCONVENIENCE} />
      }
      title=""
      description="Kindly visit FD/RD section on Axis Mobile App or Axis Internet Banking to know more"
      bottomStickerRender={
        <ErrorButton onClick={() => navigate(-1)} leftIcon='ri-home-3-line' >
          Go back
        </ErrorButton>
      }
    ></ErrorLayout >
  );
};

export const InvestmentsErrorComponent = () => (
  <ErrorLayout
    errorImage={<DsImage style={{ marginTop: "max(16vh, 4rem)", width: "149px" }} srcSet={ACCOUNT_ERROR} />}
    title="We are sorry!"
    description="We are experiencing difficulties in fetching your investment details. Visit Insta Invest on Axis Mobile App."
    bottomStickerRender={
      <DsLink href={APP_ROUTES.HOME.pathname?.toString()}>
        <ErrorButton leftIcon='ri-refresh-line'>
          Go to Insta Invest
        </ErrorButton>
      </DsLink>
    }
  ></ErrorLayout>
);

export const UnderMaintenanceErrorComponent = () => (
  <ErrorLayout
    errorImage={
      <DsImage style={{ marginTop: "max(16vh, 4rem)", width: "149px" }} srcSet={SORRY_FOR_INCONVENIENCE} />
    }
    title="Be right back!"
    description="PFM is currently on a workout schedule. It’ll come back stronger!"
  ></ErrorLayout>
);

export const TimeoutErrorComponent = ({ retryData, encrCustomerId }: TimeoutErrorComponent) => {
  const navigate = useNavigate();

  useEffect(() => {
    handleGAPush({ ...ERROR_PAGE.TIMEOUT_PAGE_ONLOAD, user_id: encrCustomerId })
    handleGAPush({ ...ERROR_PAGE.TIMEOUT_PAGE_ONLOAD_VPV, user_id: encrCustomerId })
  }, [])

  const handleGA = () => {
    handleGAPush({ ...ERROR_PAGE.TIMEOUT_PAGE_TRY_AGAIN, user_id: encrCustomerId })
  }

  return (
    <ErrorLayout
      errorImage={
        <DsImage style={{ marginTop: "max(16vh, 4rem)", width: "149px" }} srcSet={ACCOUNT_ERROR} />
      }
      title="Timeout"
      description="We are experiencing some difficulties in fetching your details."
      bottomStickerRender={
        <ErrorButton
          leftIcon='ri-refresh-line'
          onClick={() => {
            retryData ? retryData() : navigate(0);
            incRetryCount();
            handleGA();
          }}
        >
          Try Again
        </ErrorButton>
      }
    ></ErrorLayout>
  );
};

export const SessionTimeoutComponent = () => {
  return (
    <ErrorLayout
      errorImage={
        <DsImage style={{ marginTop: "max(16vh, 4rem)", width: "149px" }} srcSet={SESSION_EXPIRED} />
      }
      title="Session Expired!"
      description="Your session has expired due to inactivity."
      additionalContent={
        <DsButton
          color='secondary'
          variant='contained'
          sx={{
            color: '#F17F22',
            bgcolor: 'var(--ds-colour-neutral5)',
            border: 'none',
            padding: 'var(--ds-spacing-deepFreeze) var(--ds-spacing-glacial)',
            mt: 'var(--ds-spacing-bitterCold)',
            cursor: 'default'
          }}

        >
          <DsRemixIcon className='ri-information-line' sx={{ mr: 'var(--ds-spacing-quickFreeze)', fontSize: '10px' }} />
          <DsTypography variant="supportRegularInfo">
            Click on the home Icon on the top of the screen
          </DsTypography>
        </DsButton>


      }
    ></ErrorLayout>
  );
};

export const NoSpendsErrorComponent = () => {
  const navigate = useNavigate();
  return (
    <ErrorLayout
      errorImage={
        <DsImage srcSet={NO_INVESTMENT} style={{ marginTop: "max(16vh, 4rem)", width: "149px" }} />
      }
      title="No Transaction found!"
      description="It seems you haven’t made any spends in last six months."
      bottomStickerRender={
        <ErrorButton
          onClick={() => navigate(APP_ROUTES.HOME.pathname)}
          leftIcon='ri-home-3-line'
        >
          Go back
        </ErrorButton>
      }
    ></ErrorLayout>
  );
};


export default withRouter(CommonErrorComponent)
