import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import exp from "constants";
import { ApiResponseError } from "~/src/Lib/types/api";
import { RemoteData, IRemoteData } from "~/src/Lib/types/dataTransfer";
import { SsoModel } from "./Model";
import { SLICE_NAME } from "./Selectors";

export type SsoState = {
  sso: IRemoteData<SsoModel>;
};

const initialState: SsoState = {
  sso: new RemoteData(),
};

const SsoSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    fetchSso(state) {
      state.sso = state.sso.setLoading();
    },
    fetchSsoSuccess(state, action: PayloadAction<SsoModel>) {
      state.sso = state.sso.setData(action.payload);
    },
    fetchSsoFail(state, action: PayloadAction<ApiResponseError>) {
      state.sso = state.sso.setError(action.payload);
    },
  },
});

export const { fetchSso, fetchSsoSuccess, fetchSsoFail } = SsoSlice.actions;

const SsoReducer = SsoSlice.reducer;

export default SsoReducer;
