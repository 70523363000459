import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiResponseError } from "~/src/Lib/types/api";
import { RemoteData, IRemoteData } from "~/src/Lib/types/dataTransfer";
import { ProtectionModel } from "./Model";

export type ProtectionState = {
  protections: IRemoteData<ProtectionModel>;
};
const initialState: ProtectionState = {
  protections: new RemoteData(),
};

const ProtectionSlice = createSlice({
  name: "protections",
  initialState,
  reducers: {
    fetchProtections(state) {
      state.protections = state.protections.setLoading();
    },
    fetchProtectionsSuccess(state, action: PayloadAction<ProtectionModel>) {
      state.protections = state.protections.setData(action.payload);
    },
    fetchProtectionsFail(state, action: PayloadAction<ApiResponseError>) {
      state.protections = state.protections.setError(action.payload);
    },
  },
});

export const {
  fetchProtections,
  fetchProtectionsSuccess,
  fetchProtectionsFail,
} = ProtectionSlice.actions;

export const protectionReducer = ProtectionSlice.reducer;
