import traceActionsCreator from "../traceActionsCreator";

export const getNetworthServiceName = "networth/get";
export const getNetworthServiceTraceActions = traceActionsCreator(
  getNetworthServiceName
);

export const setNetworthsDataServiceName = "custom-financials/set";
export const setNetworthDataServiceTraceActions = traceActionsCreator(
  setNetworthsDataServiceName
);

export const deleteNetworthDataServiceName = "custom-financials/delete";
export const deleteNetworthDataServiceTraceActions = traceActionsCreator(
  deleteNetworthDataServiceName
);


export const getNetworthGoldDataServiceName = "digital-gold/buy-sell-price";
export const getNetworthGoldDataServiceTraceActions = traceActionsCreator(
  getNetworthGoldDataServiceName
);
