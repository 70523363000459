import { ModelMapper } from "~/src/Lib/types/modelmapper";

export type ChartDataProps = {
  name: string;
  y: number;
  color: string;
};

export enum RankScoreType {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
}

export const RankScoreInfo: {
  [keys in RankScoreType]: ChartDataProps;
} = {
  [RankScoreType.A]: { name: "Top 17%", y: 24.2, color: "#9D9D9D" },
  [RankScoreType.B]: { name: "Next 20%", y: 20.2, color: "#F14687" },
  [RankScoreType.C]: { name: "Next 21%", y: 17.2, color: "#9D9D9D" },
  [RankScoreType.D]: { name: "Next 22%", y: 14.2, color: "#9D9D9D" },
  [RankScoreType.E]: { name: "Bottom 20%", y: 13.2, color: "#9D9D9D" },
};

export interface defaultScoreRangeDto {
  key: string;
  value: number;
}

export interface LoanDto {
  sanctionedOn: string;
  balance: number;
  emiAmount: number;
  statusAsOn: string;
  paid: number;
  currentOverdue: number;
  dateClosed: number;
  accNo: string;
  bank: string;
  type: string;
  loanAmount: number;
  defaults: number;
  isAxisBank: boolean;
  accountStatus: string;
  amountToBePaid: number;
  loanTenure: number;
  loanSecurity: string;
  totalPayments: number;
  ontimePayments: number;
  cardNo: string;
}

export interface CreditCardDto {
  sanctionedOn: string;
  balance: number;
  statusAsOn: string;
  limit: number;
  currentUtilizationPercent: number;
  paymentDueOn: string;
  currentOverdue: number;
  cardNo: string;
  bank: string;
  type: string;
  totalOutstanding: number;
  maxUtilizationAmount: number;
  defaults: number;
  dateOfLastPayment: string;
  defaultsPercent12Month: number;
  isUnusedNonAxis: boolean;
  isAxisBank: boolean;
  currentUtilizationAmount: number;
  accountStatus: string;
  amountToBePaid: number;
  dateClosed: string;
  loanSecurity: string;
  avg6MonthUtilizationPercent: number;
  totalPayments: number;
  ontimePayments: number;
  accNo: string;
  maxUtilizationPercent: number;
  utilizationHistory: [];
}

export interface CreditDto {
  statusCode: number;
  data: {
    customerId: string;
    creditScore: number;
    onTimePayments: number;
    securedLoan: number;
    unsecuredLoan: number;
    creditUtilizationPercent: number;
    scoreRange: string;
    defaultScoreRanges: defaultScoreRangeDto[];
    enquiriesRange: string;
    onTimePaymentsPercent: number;
    totalDefaults: number;
    defaultsRange: number;
    totalCreditLines: number;
    totalEnquiries: number;
    totalLoanBalance: number;
    totalCreditAvailable: number;
    loanDisbursed: number;
    loans: LoanDto[];
    creditCards: CreditCardDto[];
    metadata: {
      reportDate: string;
      expiresOn: string;
    };
    missingDelinquencyAccounts: number;
    loanBalanceDisbursedPercent: number;
    hasAllAccountsWithinLastQuarter: boolean;
    activeCCAccounts: number;
    improperCCAccounts: string[];
    properCCAccounts: string[];
  };
}

export interface CreditModel {
  statusCode: number;
  status: any;
  data: any;
  customerId: string;
  creditScore: number;
  onTimePayments: number;
  creditUtilizationPercent: number;
  scoreRange: string;
  securedLoan: number;
  unsecuredLoan: number;
  onTimePaymentsPercent: number;
  defaultScoreRanges: defaultScoreRangeDto[];
  enquiriesRange: string;
  totalDefaults: number;
  defaultsRange: number;
  totalCreditLines: number;
  totalLoanBalance: number;
  totalCreditAvailable: number;
  totalEnquiries: number;
  loanDisbursed: number;
  loans: LoanDto[];
  creditCards: CreditCardDto[];
  metadata: {
    reportDate: string;
    expiresOn: string;
  };
  missingDelinquencyAccounts: number;
  loanBalanceDisbursedPercent: number;
  hasAllAccountsWithinLastQuarter: boolean;
  activeCCAccounts: number;
  improperCCAccounts: string[];
  properCCAccounts: string[];
}

export interface ReportMetadata {
  Enquiry_Username: string;
  ReportDate: number;
  ReportTime: number;
  Version: string;
  ReportNumber: number;
  Subscriber: string;
  Subscriber_Name: string;
  CustomerReferenceID: string;
}

export interface CreditMetadataDto {
  _id: string;
  expiresOn: string;
  reportDate: string;
  reportMetadata: ReportMetadata;
  consent: string;
  isExpired: boolean;
  isRecordMissing: boolean;
}

export interface CreditMetadataModel {
  _id: string;
  expiresOn: string;
  reportDate: string;
  reportMetadata: ReportMetadata;
  consent: string;
  isExpired: boolean;
  isRecordMissing: boolean;
}

export interface SetConsentDto {
  statusCode: number;
  status: string;
  message: string;
}
export interface SetConsentModel {
  statusCode: number;
  status: string;
  message: string;
}

export enum LoanType {
  CreditCard = "Credit Card",
  PersonalLoan = "Personal Loan",
  AutoLoan = "Auto Loan",
  HousingLoan = "Housing Loan",
  PropertyLoan = "Property Loan",
  LoanAgainstSharesSecurtiesFD = "Loan against shares/securties/FD",
  ConsumerLoan = "Consumer Loan",
  GoldLoan = "Gold Loan",
  EducationalLoan = "Educational Loan",
  Overdraft = "Overdraft",
  TelecosBrodband = "Telecos-Brodband",
  ConstructionEquipment = "Construction equipment",
  BusinessLoan = "Business Loan",
  Others = "Others",
}

export class CreditMapper implements ModelMapper<CreditDto, CreditModel> {
  toModel(dto: CreditDto): CreditModel {
    const { statusCode, data } = dto;
    return {
      data: data,
      statusCode: statusCode,
      status: statusCode === 200 ? "LOADED" : "ERROR",
      customerId: data.customerId,
      creditScore: data.creditScore || 0,
      onTimePayments: data.onTimePayments || 0,
      creditUtilizationPercent: data.creditUtilizationPercent,
      scoreRange: data.scoreRange || "",
      defaultScoreRanges: data.defaultScoreRanges || [],
      enquiriesRange: data.enquiriesRange || "",
      totalDefaults: data.totalDefaults || 0,
      defaultsRange: data.defaultsRange || 0,
      onTimePaymentsPercent: data.onTimePaymentsPercent,
      totalLoanBalance: data.totalLoanBalance || 0,
      totalCreditAvailable: data.totalCreditAvailable || 0,
      loanDisbursed: data.loanDisbursed || 0,
      securedLoan: data.securedLoan || 0,
      unsecuredLoan: data.unsecuredLoan || 0,
      totalCreditLines: data.totalCreditLines || 0,
      totalEnquiries: data.totalEnquiries || 0,
      loans: data.loans || [],
      creditCards: data.creditCards || [],
      metadata: {
        reportDate: data.metadata?.reportDate || "",
        expiresOn: data.metadata?.expiresOn || "",
      },
      missingDelinquencyAccounts: data.missingDelinquencyAccounts || 0,
      loanBalanceDisbursedPercent: data.loanBalanceDisbursedPercent || 0,
      hasAllAccountsWithinLastQuarter:
        data.hasAllAccountsWithinLastQuarter || false,
      activeCCAccounts: data.activeCCAccounts,
      improperCCAccounts: data.improperCCAccounts,
      properCCAccounts: data.properCCAccounts,
    };
  }
  toDto(model: CreditModel): CreditDto {
    throw new Error("Method not implemented. for " + typeof model);
  }
}

export class CreditMetadataMapper
  implements ModelMapper<CreditMetadataDto, CreditMetadataModel> {
  toModel(dto: CreditMetadataDto): CreditMetadataModel {
    return {
      _id: dto?._id || "",
      expiresOn: dto?.expiresOn || "",
      reportDate: dto?.reportDate || "",
      reportMetadata: dto?.reportMetadata || {},
      consent: dto?.consent || "N",
      isExpired: dto?.isExpired || false,
      isRecordMissing: dto?.isRecordMissing ?? true,
    };
  }
  toDto(model: CreditMetadataModel): CreditMetadataDto {
    throw new Error("Method not implemented. for " + typeof model);
  }
}

export class SetConsentMapper
  implements ModelMapper<SetConsentDto, SetConsentModel> {
  toModel(dto: SetConsentDto): SetConsentModel {
    return {
      status: dto?.status || "",
      statusCode: dto?.statusCode || 400,
      message: dto?.message || "",
    };
  }
  toDto(model: SetConsentModel): SetConsentDto {
    throw new Error("Method not implemented. for " + typeof model);
  }
}

// export const ShowOnExpiredText = (
//   <Text color="gray-60" textStyle="body-3" textAlign="justify" mt="var(--ds-spacing-bitterCold)">
//     Please note that in case you don’t want to give consent then we will
//     continue to show you your older credit report
//   </Text>
// );

export const ShowOnExpiredText = `Please note that in case you don’t want to give consent then we will
    continue to show you your older credit report`;

export const currentScoreColor = "#F14687";
export const ScoreColor = "#9D9D9D";

export type LoansType = {
  amount: number;
  loanVariant: string;
  title: string;
  bank: string;
};
