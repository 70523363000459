import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "./Credit.selector";
import { RemoteData, IRemoteData } from "~/src/Lib/types/dataTransfer";
import {
  CreditModel,
  CreditMetadataModel,
  SetConsentModel,
  ChartDataProps,
  currentScoreColor,
  ScoreColor,
  CreditCardDto,
  LoansType,
} from "./Credit.model";
import {
  getCreditServiceTraceActions,
  setCreditUserConsentServiceTraceActions,
  getCreditMetaDataServiceTraceActions,
} from "./Credit.action";
import {
  convertToAmount,
  getCardLastDigits,
  convertDateToStringDate,
} from "~/src/Lib/utils";

export type CreditState = {
  CreditData: IRemoteData<CreditModel>;
  CreditMetadata: IRemoteData<CreditMetadataModel>;
  SetConsent: IRemoteData<SetConsentModel>;
};
const INITIAL_STATE: CreditState = {
  CreditData: new RemoteData(),
  CreditMetadata: new RemoteData(),
  SetConsent: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      getCreditServiceTraceActions.loading,
      (state, { payload }: any) => {
        state.CreditData = payload;
      }
    );
    builder.addCase(
      getCreditServiceTraceActions.success,
      (state, { payload }: any) => {
        state.CreditData = payload;
      }
    );
    builder.addCase(
      getCreditServiceTraceActions.error,
      (state, { payload }: any) => {
        state.CreditData = payload;
      }
    );
    builder.addCase(
      getCreditMetaDataServiceTraceActions.success,
      (state, { payload }: any) => {
        state.CreditMetadata = payload;
      }
    );
    builder.addCase(
      getCreditMetaDataServiceTraceActions.loading,
      (state, { payload }: any) => {
        state.CreditMetadata = payload;
      }
    );
    builder.addCase(
      getCreditMetaDataServiceTraceActions.error,
      (state, { payload }: any) => {
        state.CreditMetadata = payload;
      }
    );
    builder.addCase(
      setCreditUserConsentServiceTraceActions.success,

      (state, { payload }: any) => {
        state.SetConsent = payload;
      }
    );
    builder.addCase(
      setCreditUserConsentServiceTraceActions.loading,

      (state, { payload }: any) => {
        state.SetConsent = payload;
      }
    );
    builder.addCase(
      setCreditUserConsentServiceTraceActions.error,

      (state, { payload }: any) => {
        state.SetConsent = payload;
      }
    );
  },
};

const CreditSlice = createSlice(sliceOptions);
export const CreditReducer = CreditSlice.reducer;

export interface PercentileType {
  name: string;
  index: number;
}

export const calcPercentile = (Credits: CreditModel): PercentileType => {
  // debugger
  const percentile = {} as PercentileType;
  Credits &&
    Credits?.defaultScoreRanges?.forEach((data, index) => {
      const totalScores = Number(Credits?.defaultScoreRanges.length);
      const currentScore = Credits?.scoreRange;
      if (data.key === currentScore) {
        percentile.name =
          (index == 0 ? "Top" : index == totalScores - 1 ? "Bottom" : "Next") +
          " " +
          data.value;
        percentile.index = index;
      }
    });

  return percentile;
};

export const calcChartData = (Credits: CreditModel): ChartDataProps[] => {
  return (Credits &&
    Credits?.defaultScoreRanges?.map((data, index) => {
      const totalScores = Number(Credits?.defaultScoreRanges.length);
      const currentScore = Credits?.scoreRange;
      return {
        name:
          (index == 0 ? "Top" : index == totalScores - 1 ? "Bottom" : "Next") +
          " " +
          data.value +
          "%",
        y: 4 - index / 2,
        color: data.key === currentScore ? currentScoreColor : ScoreColor,
      };
    })) as ChartDataProps[];
};
export interface Payments {
  paymentType: string;
  description: string;
  cardNo?: string;
  accountNo?: string;
  dueDate: string;
  bank: string;
  buttonText: string;
  isAxisBank?: boolean;
}

export interface CardDescription {
  description: string;
  additionalText: string;
}
export const getPaymentsData = (Credit: CreditModel): Payments[] => {
  const paymentData = [] as Payments[];
  if (Credit) {
    const totalCreditCards = Credit?.creditCards?.length;
    const totalLoans = Credit?.loans?.length;
    // for (let i = 0, j = 0; i < totalCreditCards + totalLoans; i++) {
    //   if (i % 2 === 0) {
    //     if (j < totalCreditCards) {
    //       Credit?.creditCards?.[j]?.currentOverdue &&
    //         paymentData.push({
    //           paymentType: "credit",
    //           cardNo: Credit?.creditCards?.[j]?.cardNo,
    //           dueDate: Credit?.creditCards?.[j]?.statusAsOn,
    //           description: `Your payment for card ${getCardLastDigits(
    //             Credit?.creditCards?.[j]?.cardNo || ""
    //           )} is due (as reported on ${
    //             Credit?.creditCards?.[j]?.statusAsOn
    //               ? convertDateToStringDate(
    //                   Credit?.creditCards?.[j]?.statusAsOn
    //                 )
    //               : ""
    //           })`,
    //           bank: Credit?.creditCards?.[j]?.bank,
    //           buttonText:
    //             Credit?.creditCards?.[j]?.isAxisBank
    //               ? "View Details"
    //               : "",
    //         });
    //       ++j;
    //     }
    //   } else {
    //     i - j < totalLoans &&
    //       Credit?.loans?.[i - j]?.currentOverdue &&
    //       paymentData.push({
    //         paymentType: "loan",
    //         dueDate: Credit?.loans?.[i - j]?.statusAsOn,
    //         accountNo: Credit?.loans?.[i - j]?.accNo,
    //         description: `Your payment for account ${getCardLastDigits(
    //           Credit?.loans?.[i - j]?.accNo || ""
    //         )} is due (as reported on ${
    //           Credit?.loans?.[i - j]?.statusAsOn
    //             ? convertDateToStringDate(Credit?.loans?.[i - j]?.statusAsOn)
    //             : ""
    //         })`,
    //         bank: Credit?.loans?.[i - j]?.bank,
    //         buttonText:
    //           Credit?.loans?.[i - j]?.isAxisBank
    //             ? "View Detaiils"
    //             : "",
    //       });
    //   }
    // }
    for (let i = 0, j = 0; i < totalCreditCards + totalLoans; i++) {
      if (j < totalCreditCards) {
        if (Credit?.creditCards?.[j]?.currentOverdue) {
          paymentData.push({
            paymentType: "credit",
            cardNo: Credit?.creditCards?.[j]?.cardNo,
            dueDate: Credit?.creditCards?.[j]?.statusAsOn,
            description: `Please clear the overdue amount of ${convertToAmount(
              Credit?.creditCards?.[j]?.currentOverdue || 0
            )} on your ${Credit?.creditCards?.[
              j
            ]?.bank.toLocaleUpperCase()} credit card ending ${getCardLastDigits(
              Credit?.creditCards?.[j]?.cardNo || ""
            )} as on ${Credit?.creditCards?.[j]?.statusAsOn
              ? convertDateToStringDate(Credit?.creditCards?.[j]?.statusAsOn)
              : ""
              } `,
            bank: Credit?.creditCards?.[j]?.bank,
            buttonText: Credit?.creditCards?.[j]?.isAxisBank
              ? "View Details"
              : "",
          });
        }
        if (Credit?.creditCards?.[j]?.defaultsPercent12Month > 0) {
          paymentData.push({
            paymentType: "credit",
            cardNo: Credit?.creditCards?.[j]?.cardNo,
            dueDate: Credit?.creditCards?.[j]?.statusAsOn,
            description: `You have defaulted ${Credit?.creditCards?.[j]?.defaults || 0
              } times on ${(Credit?.creditCards?.[
                j
              ]?.bank).toLocaleUpperCase()} credit card ending ${getCardLastDigits(
                Credit?.creditCards?.[j]?.cardNo || ""
              )} over last 12 months.${!Credit?.creditCards?.[j].isAxisBank
                ? " You can apply for Axis Bank Credit card"
                : ""
              }`,
            bank: Credit?.creditCards?.[j]?.bank,
            buttonText: Credit?.creditCards?.[j]?.isAxisBank
              ? "View Details"
              : "",
            isAxisBank: Credit?.creditCards?.[j].isAxisBank,
          });
        }
        if (
          Credit?.creditCards?.[j]?.isUnusedNonAxis &&
          Credit?.creditCards?.[j]?.accountStatus.toUpperCase() != "CLOSED"
        ) {
          paymentData.push({
            paymentType: "credit",
            cardNo: Credit?.creditCards?.[j]?.cardNo,
            dueDate: Credit?.creditCards?.[j]?.statusAsOn,
            description: `You have not used your ${Credit?.creditCards?.[
              j
            ]?.bank.toLocaleUpperCase()} credit card ending ${getCardLastDigits(
              Credit?.creditCards?.[j]?.cardNo || ""
            )} in the last 6 months. To maintain a healthy score, we recommend you to close the card if not required.`,
            bank: Credit?.creditCards?.[j]?.bank,
            buttonText: Credit?.creditCards?.[j]?.isAxisBank
              ? "View Details"
              : "",
          });
        }
        // if (
        //   Credit?.creditCards?.[j]?.currentOverdue > 0 ||
        //   Credit?.creditCards?.[j]?.isUnusedNonAxis ||
        //   Credit?.creditCards?.[j]?.defaultsPercent12Month > 0
        // ) {
        ++j;
        // }
      } else {
        i - j < totalLoans &&
          Credit?.loans?.[i - j]?.currentOverdue &&
          paymentData.push({
            paymentType: "loan",
            dueDate: Credit?.loans?.[i - j]?.statusAsOn,
            accountNo: Credit?.loans?.[i - j]?.accNo,
            description: `Please clear the overdue amount of ${convertToAmount(
              Credit?.loans?.[i - j]?.currentOverdue || 0
            )} on your ${Credit?.loans?.[i - j]?.bank.toLocaleUpperCase()} ${(
              Credit?.loans?.[i - j]?.type || ""
            ).toLocaleLowerCase()} ending ${getCardLastDigits(
              Credit?.loans?.[i - j]?.accNo || ""
            )} as on ${Credit?.loans?.[i - j]?.statusAsOn
              ? convertDateToStringDate(Credit?.loans?.[i - j]?.statusAsOn)
              : ""
              }`,
            bank: Credit?.loans?.[i - j]?.bank,
            buttonText: "",
            /**
             * is commented as we dont' want to show view Details for loan in crdit score nudge
             * Credit?.loans?.[i - j]?.isAxisBank? "View Details": "Show Details",
             */
          });
      }
    }
  }
  return paymentData;
};

export const getCardUsageData = (
  cardData: CreditCardDto[]
): CardDescription[] => {
  if (!cardData) return [] as CardDescription[];
  const cardUsageData = [] as CardDescription[];
  cardData?.map((card) => {
    card?.currentUtilizationAmount &&
      card?.currentUtilizationAmount >= card?.maxUtilizationAmount &&
      card?.currentUtilizationPercent >= 30 &&
      cardUsageData.push({
        description: `We see that you have crossed maximum card utilization of ${card?.maxUtilizationPercent || 0
          }% over last 6 months for ${card?.bank.toLocaleUpperCase()} credit card ending ${getCardLastDigits(
            card?.cardNo || ""
          )}, please ensure on-time payments`,
        additionalText: "",
      });
  });
  return cardUsageData;
};
export const getSecuredUnsecuredLoans = (
  Credits: CreditModel,
  variant: string
): LoansType[] => {
  const loanLines: LoansType[] = Credits?.loans
    ? Credits?.loans
      ?.filter(
        (loan) =>
          loan?.loanSecurity === variant && loan?.accountStatus === "Active"
      )
      ?.map((data) => ({
        amount: data?.loanAmount,
        loanVariant: "loan",
        title: data?.type,
        bank: data?.bank,
      }))
    : ([] as LoansType[]);
  const creditLines: LoansType[] = Credits?.creditCards
    ? Credits?.creditCards
      ?.filter(
        (card) =>
          card?.loanSecurity === variant && card?.accountStatus === "Active"
      )
      ?.map((data) => ({
        amount: data?.limit,
        loanVariant: "creditCard",
        title: data?.type,
        bank: data?.bank,
      }))
    : ([] as LoansType[]);
  return [...loanLines, ...creditLines];
};
