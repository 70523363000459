import { jsx as _jsx } from "react/jsx-runtime";
import { PureComponent } from 'react';
import Chip from '@mui/material/Chip';
import { DsTagDefaultProps } from './DsTag.Types';
import { DsRemixIcon } from '../DsRemixIcon';
export class DsTag extends PureComponent {
    static defaultProps = DsTagDefaultProps;
    handleClick = () => {
        const { value, onClick } = this.props;
        if (typeof onClick === 'function') {
            onClick(value);
        }
    };
    handleDelete = () => {
        const { value, onDelete } = this.props;
        if (typeof onDelete === 'function') {
            onDelete(value);
        }
    };
    render() {
        const { selected, onClick, onDelete, ...chipProps } = this.props;
        const color = (selected && 'secondary') || 'default';
        return (_jsx(Chip, { deleteIcon: _jsx(DsRemixIcon, { className: "ri-close-circle-fill" }), skipFocusWhenDisabled: true, ...chipProps, clickable: true, color: color, onDelete: (onDelete && this.handleDelete) || undefined, onClick: this.handleClick }));
    }
}
