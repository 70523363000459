export const DsSliderOverrides = {
    MuiSlider: {
        styleOverrides: {
            valueLabel: {
                backgroundColor: 'var(--ds-colour-surfaceSecondary)',
                top: '0px',
                color: 'var(--ds-colour-typoPrimary)'
            }
        }
    }
};
