import React, { createElement } from 'react'
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'
import { PersistGate } from 'redux-persist/integration/react'

import AppStore, { PersistedAppStore } from '~/src/Configurations/AppStore'
import App from './App'
import SplashScreen from "~/src/Components/SplashScreen";
import {
  DISABLE_STRICT_MODE_TEMP
} from "~/src/Configurations/env";

const container = document.getElementById('app')!

const root = createRoot(container)


class MainApp extends React.Component {

  constructor(props: Readonly<{}>) {
    super(props)
  }


  render() {
    return (
      <Provider store={AppStore}>
        <PersistGate persistor={PersistedAppStore} onBeforeLift={() => undefined}>
          {(persisted: boolean) =>
            <>
              <SplashScreen />
              <App persisted={persisted} />
            </>
          }
        </PersistGate>
      </Provider>
    )
  }
}


root.render(
  DISABLE_STRICT_MODE_TEMP ?
    <MainApp />
    :
    <React.StrictMode>
      <MainApp />
    </React.StrictMode>
)

