import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "customer";

const customerDataSelect = (state: any) => state[SLICE_NAME];

export const getCustomerSelector = createSelector(
  customerDataSelect,
  (customerData: any) => customerData
);
