import React, { FC, useEffect, useMemo, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import CustomEvents from "highcharts-custom-events";
import {
  SpendCategoryInfoModel,
  SpendCategoryType,
  Transaction,
} from "~/src/Redux/SpendsAnalyzer/Model";
import { capitalizeFirstLetterString, convertToAmount } from "~/src/Lib/utils";
import {
  getFilteredData,
  monthCategoryMap,
} from "~/src/Redux/SpendsAnalyzer/Reducer";
import { DsBox, DsRemixIcon, DsTag } from "@am92/react-design-system";
import { handleGAPush } from "~/src/GoogleAnalytics/googleAnalytics.main";
import { SPENDS_PAGE } from "~/src/GoogleAnalytics/googleAnalytics.model";
import { isEmpty } from "lodash";

CustomEvents(Highcharts);

export enum CategoryType {
  All = "All",
  Payments = "Payments",
  Entertainment = "Entertainment",
  Travel = "Travel",
  Healthcare = "Healthcare",
  Investment = "Investment",
  Food = "Food",
  Shopping = "Shopping",
  Education = "Education",
  "Fund Transfer" = "Fund Transfer",
  Others = "Others",
}
const MonthsShort = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

function extractKeyMonths() {
  const currentMonthIndex = new Date().getMonth();
  return [...Array(6)]
    .map((_, i) =>
      currentMonthIndex - i >= 0
        ? MonthsShort[currentMonthIndex - i]
        : MonthsShort[12 + currentMonthIndex - i]
    )
    .reverse();
}

interface MergedDataItem {
  [key: string]: number;
}

type MonthlySpendChartProps = {
  monthWiseSpend: { [key: string]: Transaction[] };
  mapData?: number[][];
  months: string[];
  categories: string[];
  transactionType: string;
  encrCustomerId: string;
  selectedMonth: 3 | 6 | 9 | 12;
  SpendsCategoryInfoMapped: SpendCategoryInfoModel
}

type SpendCategoryData = Partial<{
  [key in CategoryType]: {
    isChecked: boolean;
    order: number;
    iconColor?: string;
    textColor?: string;
  };
}>;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const MonthlySpendChart = ({
  mapData,
  months,
  categories,
  monthWiseSpend,
  transactionType: spendType,
  encrCustomerId,
  selectedMonth,
  SpendsCategoryInfoMapped
}: MonthlySpendChartProps) => {

  const spendsCatTop: SpendCategoryData = {
    [CategoryType.All]: {
      iconColor: "",
      textColor: "",
      isChecked: true,
      order: 30,
    },
    [CategoryType.Entertainment]: {
      textColor: "white",
      isChecked: false,
      order: 5,
    },
    [CategoryType.Healthcare]: {
      textColor: "white",
      isChecked: false,
      order: 4,
    },
    [CategoryType.Food]: {
      textColor: "white",
      isChecked: false,
      order: 3,
    },
    [CategoryType.Education]: {
      textColor: "white",
      isChecked: false,
      order: 2,
    },
    [CategoryType.Others]: {
      textColor: "white",
      isChecked: false,
      order: 1,
    },

  };
  const spendsCatBottom: SpendCategoryData = {
    [CategoryType.Payments]: {
      textColor: "white",
      isChecked: false,
      order: 5,
    },
    [CategoryType.Travel]: {
      textColor: "white",
      isChecked: false,
      order: 4,
    },
    [CategoryType.Investment]: {
      textColor: "white",
      isChecked: false,
      order: 3,
    },
    [CategoryType.Shopping]: {
      textColor: "white",
      isChecked: false,
      order: 2,
    },
    [CategoryType["Fund Transfer"]]: {
      textColor: "white",
      isChecked: false,
      order: 1,
    },
  };

  const [isFilter, setFilter] = useState(false);
  const [spendCatTopData, setSpendCatTopData] = useState<SpendCategoryData>(spendsCatTop);
  const [spendCatTopDataDefault, setSpendCatTopDataDefault] = useState<SpendCategoryData>({});
  const [spendCatBottomData, setSpendCatBottomData] = useState<SpendCategoryData>(spendsCatBottom);
  const [spendCatBottomDataDefault, setSpendCatBottomDataDefault] = useState<SpendCategoryData>({});

  const initializeState = () => {
    const deepCopy = (obj: any) => {
      return JSON.parse(JSON.stringify(obj));
    };

    const newTopData = deepCopy(spendsCatTop);
    const newBottomData = deepCopy(spendsCatBottom);

    setSpendCatTopData(newTopData);
    setSpendCatBottomData(newBottomData);

    const mergedSpendsCatdata = { ...newTopData, ...newBottomData };
    let isDataOrdered = false;

    Object.keys(mergedSpendsCatdata).map((key) => {
      const cat = mergedSpendsCatdata[key];
      if (key !== "All" && cat.order > 5 && cat.order < 16) {
        isDataOrdered = true;
      }
    });

    if (!isDataOrdered && monthWiseSpend && !isEmpty(monthWiseSpend)) {
      const SpendsData = getFilteredData({}, monthWiseSpend);
      const mappedData = SpendsData ? monthCategoryMap(SpendsData, SpendsCategoryInfoMapped, months) : [];
      const sums = Array.from({ length: mappedData[0]?.length }, () => 0);
      mappedData.forEach((arr) => {
        arr.forEach((num, index) => {
          sums[index] += num;
        });
      });

      const mergedData = Object.keys(SpendCategoryType).map((key, index) => ({
        [key]: sums[index],
      }));

      const dividedDataTop: MergedDataItem = {};
      const dividedDataBottom: MergedDataItem = {};

      mergedData.forEach((item) => {
        const key = Object.keys(item)[0];
        if (spendsCatTop.hasOwnProperty(key)) {
          dividedDataTop[key] = item[key];
        } else if (spendsCatBottom.hasOwnProperty(key)) {
          dividedDataBottom[key] = item[key];
        }
      });

      Object.keys(dividedDataTop).forEach((key) => {
        dividedDataTop[key] = parseFloat(dividedDataTop[key]?.toString());
      });

      Object.keys(dividedDataBottom).forEach((key) => {
        dividedDataBottom[key] = parseFloat(dividedDataBottom[key]?.toString());
      });

      const top3CategoriesTop = getTopCategories(dividedDataTop);
      const top3CategoriesBottom = getTopCategories(dividedDataBottom);

      let topStartOrder = 9;
      let bottomStartOrder = 9;

      for (const category in top3CategoriesTop) {
        if (newTopData.hasOwnProperty(category)) {
          newTopData[category].order = topStartOrder;
          topStartOrder -= 1;
        }
      }

      for (const category in top3CategoriesBottom) {
        if (newBottomData.hasOwnProperty(category)) {
          newBottomData[category].order = bottomStartOrder;
          bottomStartOrder -= 1;
        }
      }

      setSpendCatTopDataDefault(newTopData ? sortObject(deepCopy(newTopData)) : spendsCatTop);
      setSpendCatBottomDataDefault(newBottomData ? sortObject(deepCopy(newBottomData)) : spendsCatBottom);
      setSpendCatTopData(newTopData ? sortObject(newTopData) : spendsCatTop);
      setSpendCatBottomData(newBottomData ? sortObject(newBottomData) : spendsCatBottom);
    }
    else {
      setSpendCatTopDataDefault(spendsCatTop);
      setSpendCatBottomDataDefault(spendsCatBottom);
    }
  };

  useEffect(() => {
    initializeState();
  }, []);

  let filteredData: { [key: string]: Transaction[] } = {};

  function areAllChecked(obj: any) {
    for (const key in obj) {
      if (key === CategoryType.All) {
        continue;
      }
      if (obj.hasOwnProperty(key) && !obj[key].isChecked) {
        return false;
      }
    }
    return true;
  }
  useEffect(() => {
    setFilter(!isFilter);
  }, [monthWiseSpend]);

  const getTopCategories = (data: MergedDataItem): MergedDataItem => {
    const sortedCategories = Object.keys(data).filter((val) => data[val] > 0)
      .sort((a, b) => data[b] - data[a])
      .slice(0, 3);

    const topCategories = sortedCategories.reduce((obj: MergedDataItem, key: string) => {
      obj[key] = data[key];
      return obj;
    }, {});

    return topCategories;
  };

  monthWiseSpend = useMemo(() => {
    const mergeCat = { ...spendCatBottomData, ...spendCatTopData };
    let catFilter = {};
    const selectedCat = Object.fromEntries(
      Object.entries(mergeCat).filter(
        ([key, value]: any) =>
          value?.isChecked === true && key !== CategoryType.All
      )
    );
    if (selectedCat && Object.keys(selectedCat).length > 0)
      catFilter = Object.keys(selectedCat).reduce((prev, category) => {
        const subcategories =
          SpendsCategoryInfoMapped[category as SpendCategoryType].subcategory;
        return {
          ...prev,
          [category]: subcategories,
        };
      }, {});
    const data = Object.fromEntries(Object.entries(monthWiseSpend).slice(-(selectedMonth)))
    filteredData = getFilteredData(catFilter, data);
    mapData = monthCategoryMap(filteredData, SpendsCategoryInfoMapped, months);
    return filteredData;
  }, [isFilter, spendType, spendCatBottomData, spendCatTopData, selectedMonth]);

  const sortObject = (obj: any) => {
    const sortedKeys = Object.keys(obj).sort((key1, key2) => {
      const { order: order1 } = obj[key1];
      const { order: order2 } = obj[key2];
      return order2 - order1;
    });

    const sortedObj: any = {};
    sortedKeys.forEach((key) => {
      sortedObj[key] = obj[key];
    });
    return sortedObj;
  };

  useEffect(() => {
    const merged = { ...spendCatBottomData, ...spendCatTopData };

    const isAllCatSelected = areAllChecked(merged);

    if (isAllCatSelected && merged && !merged[CategoryType.All]!.isChecked) {
      SelectAllCat();
    }

    const selectedCat = Object.fromEntries(
      Object.entries(merged).filter(
        ([key, value]: any) =>
          value?.isChecked === true
      )
    );
    const label = Object.keys(selectedCat).join(", ")
    if (label)
      handleGAPush({ ...SPENDS_PAGE.SPENDS_CATEGORY_SELECT_FILTER, label: Object.keys(selectedCat).join(", "), user_id: encrCustomerId })

  }, [spendCatTopData, spendCatBottomData]);

  const uncheckAllCategoriesExceptAll = () => {
    const updateTopCategoryData = (data: { [key: string]: any }) => {
      for (const key in data) {
        const updatedItem = data[key];
        updatedItem.order =
          key === CategoryType.All
            ? updatedItem.order
            : spendCatTopDataDefault[key as CategoryType]?.order;
        updatedItem.isChecked = key === CategoryType.All;
        data[key] = updatedItem;
      }
    };

    const updateBottomCategoryData = (data: { [key: string]: any }) => {
      for (const key in data) {
        const updatedItem = data[key];
        updatedItem.order =
          key === CategoryType.All
            ? updatedItem.order
            : spendCatBottomDataDefault[key as CategoryType]?.order;
        updatedItem.isChecked = key === CategoryType.All;
        data[key] = updatedItem;
      }
    };

    updateTopCategoryData(spendCatTopData);
    updateBottomCategoryData(spendCatBottomData);
  };

  const SelectAllCat = (defaultStatus?: boolean) => {
    const allCategoryData = spendCatTopData[CategoryType.All];
    if (!allCategoryData) return;

    const { isChecked } = allCategoryData;
    const status = defaultStatus ?? !isChecked;

    if (defaultStatus === false) {
      const updatedItem = spendCatTopData[CategoryType.All];
      if (!updatedItem) return;
      updatedItem.order = updatedItem.isChecked
        ? spendCatTopDataDefault[CategoryType.All]?.order ?? 0
        : (spendCatTopDataDefault[CategoryType.All]?.order ?? 0) + 15;
      updatedItem.isChecked = status;
      const data = { ...spendCatTopData, [CategoryType.All]: updatedItem };
      const sorted = sortObject(data);
      setSpendCatTopData(sorted);
      return;
    }

    Object.keys(spendCatTopData).forEach((key) => {
      const updatedItem = spendCatTopData[key as CategoryType];
      if (!updatedItem) return;
      updatedItem.order = !status
        ? spendCatTopDataDefault[key as CategoryType]?.order ?? 0
        : (spendCatTopDataDefault[key as CategoryType]?.order ?? 0) + 15;
      updatedItem.isChecked = status;
      const data = { ...spendCatTopData, [key as CategoryType]: updatedItem };
      const sorted = sortObject(data);
      setSpendCatTopData(sorted);
    });

    Object.keys(spendCatBottomData).forEach((key) => {
      const updatedItem = spendCatBottomData[key as CategoryType];
      if (!updatedItem) return;
      updatedItem.order = !status
        ? spendCatBottomDataDefault[key as CategoryType]?.order ?? 0
        : (spendCatBottomDataDefault[key as CategoryType]?.order ?? 0) + 15;
      updatedItem.isChecked = status;
      const data = { ...spendCatBottomData, [key as CategoryType]: updatedItem };
      const sorted = sortObject(data);
      setSpendCatBottomData(sorted);
    });
  };


  const handleBottomCatClick = (id: CategoryType) => {
    if (spendCatTopData[CategoryType.All]?.isChecked) {
      SelectAllCat(false);
    }
    const updatedItem = spendCatBottomData[id];
    if (updatedItem) {
      updatedItem.order = updatedItem.isChecked
        ? spendCatBottomDataDefault[id]?.order ?? 0
        : (spendCatBottomDataDefault[id]?.order ?? 0) + 15;
      updatedItem.isChecked = !updatedItem.isChecked;
      const data = { ...spendCatBottomData, [id]: updatedItem };
      const sorted = sortObject(data);
      setSpendCatBottomData(sorted);
    }
  };

  const handleTopCatClick = (id: CategoryType) => {
    if (id !== CategoryType.All && spendCatTopData[CategoryType.All]?.isChecked) {
      SelectAllCat(false);
    }
    if (id === CategoryType.All) {
      SelectAllCat();
      uncheckAllCategoriesExceptAll();
      return;
    }
    const updatedItem = spendCatTopData[id];
    if (updatedItem) {
      updatedItem.order = updatedItem.isChecked
        ? spendCatTopDataDefault[id]?.order ?? 0
        : (spendCatTopDataDefault[id]?.order ?? 0) + 15;
      updatedItem.isChecked = !updatedItem.isChecked;
      const data = { ...spendCatTopData, [id]: updatedItem };
      const sorted = sortObject(data);
      setSpendCatTopData(sorted);
    }
  };

  return (
    <>
      <DsBox
        sx={{
          display: 'flex',
          "&::-webkit-scrollbar": {
            width: "var(--ds-spacing-zero)",
            height: "var(--ds-spacing-zero)",
          },
          "&::-webkit-scrollbar-track": {
            width: "var(--ds-spacing-zero)",
            height: "var(--ds-spacing-zero)",
          },
          whiteSpace: "nowrap",
          overflow: "auto",
          position: "relative",
          gap: 'var(--ds-spacing-glacial)'
        }}
        pt='var(--ds-spacing-glacial)'
      >
        {Object.keys(spendCatTopData).map((id: string) => {
          const top = spendCatTopData[id as CategoryType];
          const topData = SpendsCategoryInfoMapped[id as SpendCategoryType];
          const item = { ...top, ...topData };
          if (id === CategoryType.All && item.isChecked) {
            item.iconColor = "#ED1164";
            item.textColor = "white";
          }
          return (
            <DsTag
              key={item.order}
              onClick={() => handleTopCatClick(id as CategoryType)}
              sx={{
                bgcolor: item.iconColor,
                color: item.textColor,
                border: (item?.isChecked || id === CategoryType.All) ? "1px solid" : "none",
                borderColor: item?.isChecked ? 'var(--ds-colour-strokeSelected)' : 'var(--ds-colour-strokeDefault)',
                opacity: (item?.isChecked || id === CategoryType.All) ? 1 : 0.5
              }}
              deleteIcon={
                item?.isChecked ? (
                  <DsRemixIcon
                    className="ri-close-circle-fill"
                    sx={{
                      color: 'var(--ds-colour-surfacePrimary) !important'
                    }}
                  />) : <></>
              }
              label={
                item.label ?? id
              }
            />
          );
        })}
      </DsBox>
      <DsBox
        sx={{
          display: 'flex',
          "&::-webkit-scrollbar": {
            width: "var(--ds-spacing-zero)",
          },
          "&::-webkit-scrollbar-track": {
            width: "var(--ds-spacing-zero)",
          },
          whiteSpace: "nowrap",
          overflow: "auto",
          position: "relative",
          gap: 'var(--ds-spacing-glacial)',

        }}
        mt='var(--ds-spacing-frostbite)'
        pb='var(--ds-spacing-bitterCold)'
      >
        {Object.keys(spendCatBottomData).map((id) => {
          const top = spendCatBottomData[id as CategoryType];
          const topData = SpendsCategoryInfoMapped[id as SpendCategoryType];
          const item = { ...top, ...topData };
          return (
            <DsTag
              key={item.order}
              onClick={() => handleBottomCatClick(id as CategoryType)}
              sx={{
                bgcolor: item.iconColor,
                color: item.textColor,
                border: !item?.isChecked ? "none" : "1px solid",
                borderColor: item?.isChecked ? 'var(--ds-colour-strokeSelected)' : 'var(--ds-colour-strokeDefault)',
                opacity: item?.isChecked ? 1 : 0.4
              }}
              deleteIcon={
                item?.isChecked ? (
                  <DsRemixIcon
                    className="ri-close-circle-fill"
                    sx={{
                      color: 'var(--ds-colour-surfacePrimary) !important'
                    }}
                  />) : <></>
              }

              label={
                item.label
              }
            />
          );
        })}
      </DsBox>
      <SpendCart
        categories={categories}
        months={months}
        mapData={mapData}
        monthWiseSpend={monthWiseSpend}
        spendType={spendType}
        SpendsCategoryInfoMapped={SpendsCategoryInfoMapped}
      />
    </>
  );
};

type SpendCartProps = {
  mapData?: number[][];
  months: string[];
  categories: string[];
  monthWiseSpend: { [key: string]: Transaction[] };
  spendType?: string;
  SpendsCategoryInfoMapped: SpendCategoryInfoModel
};

export const SpendCart: FC<SpendCartProps> = ({
  mapData,
  months,
  categories,
  SpendsCategoryInfoMapped
}: SpendCartProps) => {
  const XLabelKeys =
    months.length > 0
      ? months.map((i) => MonthsShort[Number(i) - 1])
      : extractKeyMonths();

  const xAxisCategories = XLabelKeys.length === 12 ? XLabelKeys.map((month, index) => {
    const hasMarker = index % 2 === 0;
    return {
      name: hasMarker ? `<span style="color:#12877F;font-weight:bold;">●</span>` : month,
    };
  }) : XLabelKeys;

  const isMobile = XLabelKeys.length === 12 ? window.innerWidth < 415 : false;

  const options = {
    chart: {
      type: "column",
      height: 220,
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        pointWidth: 16,
        dataLabels: {
          enabled: false,
        },
        area: {
          fillColor: "transparent",
          marker: {
            enabled: false,
          },
        },
      },
      series: {
        tooltip: {
          headerFormat: "",
          pointFormatter: function () {
            const self = this as any;
            const month = XLabelKeys[self.x];
            const value = convertToAmount(self.y);
            return `${capitalizeFirstLetterString(month)}<br/>${self.series.name} = ${value}`;
          },
        },
      },

    },
    series: categories.map((cat, idx) => ({
      name: cat,
      minPointLength: 3,
      data:
        mapData &&
        mapData
          .map((x) => (x[idx] !== 0 ? x[idx] : undefined))
          .map((y, i) => {
            return i < 5 ? { y: y, opacity: 1 } : { y: y };
          }),
      color: SpendsCategoryInfoMapped[cat as SpendCategoryType].iconColor,
    })),
    xAxis: {
      categories: xAxisCategories,
      labels: {
        rotation: isMobile && -60,
        formatter: function (): string {
          const self = this as any;
          const values = XLabelKeys.length === 12 ? self.value.name : self.value
          return values;
        },
        style: {
          fontSize: "11px",
          fontFamily: "Lato",
          color: "#12877F",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "15px",
          letterSpacing: "0.32px",
        },
      },
      crosshair: true,
    },
    yAxis: {
      tickPositioner: function () {
        const self = this as any;
        const dataMax = self.dataMax ? Math.ceil(self.dataMax / 500) * 500 : 15000;
        const quarterMax = Math.ceil(dataMax / 4 / 500) * 500;
        const halfMax = Math.ceil(dataMax / 2 / 500) * 500;
        const threeQuarterMax = Math.ceil((dataMax * 3) / 4 / 500) * 500;

        if (self.dataMax === undefined || self.dataMax === null || self.dataMax <= 0) {
          return [0, 8000, 15000];
        }
        return [0, quarterMax, halfMax, threeQuarterMax, dataMax];
      },
      min: 0,
      opposite: true,
      title: {
        text: null,
      },
      startOnTick: true,
      gridLineColor: "#E2E2E2",
      gridLineDashStyle: "longdash",
      stackLabels: {
        enabled: false,
      },
      labels: {
        style: {
          color: "#12877F",
        },
        formatter: function (): string {
          const self = this as any;
          if (self.value > 1000000) {
            return "₹" + Highcharts.numberFormat(self.value / 1000, 0) + "M";
          } else if (self.value > 1000) {
            return "₹" + Highcharts.numberFormat(self.value / 1000, 0) + "K";
          } else {
            return "₹" + self.value;
          }
        },
      },
    },
  };

  return (
    <>
      <DsBox>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </DsBox>
    </>
  );
};
