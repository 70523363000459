export interface ElementInterface {
  event: string;
  category: string;
  action: string;
  label: string;
  cd2: string; //"<<pass encrypted customer id>>";
  cd24: string; //"<<pass platform either website or internet-banking or mobile-app>>";
  page?: string;
  title?: string;
  user_id: string;
  platform: string | null;
}

export interface homePageHelperInterface {
  events: string;
  category: string;
  categoryType: {
    [key: string]: {
      action: string;
    };
  };
  cd24: string;
  cd2: string;
  actions: {
    [key: string]: string;
  };
  labels: {
    [key: string]: string;
  };
  page: string;
  title: string;
  user_id: string;
  platform: string;
}

export interface CategoryHelperInterface {
  events: string;
  category: string;
  cd24: string;
  cd2: string;
  action: string;
  labels: string;
  page: string;
  title: string;
  user_id: string;
  platform: string;
}

export const homePageHelperObject: homePageHelperInterface = {
  events: "",
  category: "",
  categoryType: {
    Investments: {
      action: "Investments",
    },
    Credit: {
      action: "Credit",
    },
    Notification: {
      action: "Notification",
    },
    Insurance: {
      action: "Insurance",
    },
    Spends: {
      action: "Spends",
    },
  },
  cd24: "",
  cd2: "",
  actions: {
    banner: "banner",
    click: "click",
    quickLinks: "quick links",
  },
  labels: {
    banner: " bill",
    click: " back",
  },
  page: "",
  title: "",
  user_id: "",
  platform: ""
};

export const CategoryHelperObject: CategoryHelperInterface = {
  events: "",
  category: "",
  action: "",
  cd24: "",
  cd2: "",
  labels: "",
  page: "",
  title: "",
  user_id: "",
  platform: ""
};
