import { createSelector } from '@reduxjs/toolkit'

export const SLICE_NAME = 'activity'

const select = (state: any) => state[SLICE_NAME]

export const getActivitySelector = (state: any) => {
  return state[SLICE_NAME].activity;
}

