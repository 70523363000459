import { DsBox, DsStack, DsTypography } from '@am92/react-design-system';
import React, { Children, ReactElement } from 'react'


export type ErrorLayoutProps = {
  errorIcon?: ReactElement;
  // errorImage: DsImageProps['srcSet'];
  errorImage: ReactElement
  title: string;
  description: string | ReactElement;
  additionalDescription?: string;
  children?: typeof Children;
  bottomSpacerSize?: string | number;
  bottomStickerRender?: ReactElement;
  additionalContent?: ReactElement;
  isNeutralError?: boolean;
};


export default class ErrorLayout extends React.Component<ErrorLayoutProps> {
  render() {
    const {
      errorImage,
      title,
      description,
      additionalDescription,
      children,
      bottomStickerRender,
      bottomSpacerSize,
      isNeutralError,
      additionalContent }: ErrorLayoutProps = this.props
    return (
      <>
        <DsBox>
          <DsStack
            alignItems='center'
            justifyContent='center'
            height='90vh'
            direction='column'
            mt='var(--ds-spacing-blazing)'
            mx='var(--ds-spacing-tropical)'
          >
            <DsBox>
              {errorImage}
            </DsBox>
            <DsTypography
              variant='headingBoldSmall'
              color='var(--ds-color-typoSecondary)'
              my='var(--ds-spacing-mild)'
              textAlign='center'
            >
              {title}
            </DsTypography>
            <DsTypography variant='bodyRegularMedium' color='var(--ds-color-typoSecondary)' mt='var(--ds-spacing-quickFreeze)' textAlign='center'
              style={{ whiteSpace: 'pre-line' }}
            >
              {description}
            </DsTypography>
            {additionalDescription && (
              <DsTypography
                color='var(--ds-color-surfaceTertiary)'
                mt='var(--ds-spacing-bitterCold)'
                textAlign='center'
              >
                {additionalDescription}
              </DsTypography>
            )}
            {additionalContent}

            <DsBox mt='var(--ds-spacing-superheated)'>
              {bottomStickerRender || null}
            </DsBox>
          </DsStack>
        </DsBox>
        {children || null}
      </>
    )
  }
}
