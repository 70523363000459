import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "creditData";

const select = (state: any) => state[SLICE_NAME];

const creditDataSelector = (state: any) => state[SLICE_NAME];

export const getCreditSelector = createSelector(
  creditDataSelector,
  (creditData: any) => creditData
);

export const getCreditMetadataSelector = createSelector(
  creditDataSelector,
  (CreditMetadata: any) => CreditMetadata
);
export const getSetConsentSelector = createSelector(
  creditDataSelector,
  (SetConsent: any) => SetConsent
);
