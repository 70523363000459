import traceActionsCreator from "../traceActionsCreator";
export const getCreditsServiceName = "credits/get";
export const getCreditServiceTraceActions = traceActionsCreator(
  getCreditsServiceName
);

export const getCreditsMetaDataServiceName = "credits/getMetaData";
export const getCreditMetaDataServiceTraceActions = traceActionsCreator(
  getCreditsMetaDataServiceName
);

export const setCreditUserConsentServiceName = "credits/userConsent";
export const setCreditUserConsentServiceTraceActions = traceActionsCreator(
  setCreditUserConsentServiceName
);
