import React, { Component, ReactElement } from "react";
import { DsImage, DsBox } from "@am92/react-design-system";

type OverlayLoaderProps = {
  bg?: string;
};

export const axisLoaderGif = {
  src: new URL("~/public/assets/images/loader/loading.gif", import.meta.url).href,
  alt: "Axis Loader",
  as: "image/gif",
};
export class OverlayLoader extends Component<OverlayLoaderProps> {
  render(): ReactElement {
    const { bg } = this.props;
    return (
      <DsBox
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor={bg ? bg : "var(--ds-colour-surfaceBackground)"}
        position="fixed"
        margin="auto"
        sx={{
          maxWidth: "600px",
          position: "relative",
          zIndex: "99",
          // maxHeight: "95vh",
        }}
      >
        <DsBox>
          <DsImage srcSet={[axisLoaderGif]} style={{
            maxWidth: "100%",
            height: 'auto',
            display: 'block',
            margin: 'auto',
          }} />
        </DsBox>
      </DsBox>
    );
  }
}
export type WithLoaderProps = {
  setLoading: (isLoading: boolean) => void;
};
const withLoader =
  <Props extends Record<string, unknown> | undefined>(
    WrappedComponent: React.ComponentType<Props & WithLoaderProps>
  ) =>
    class LoaderHoc extends Component<Props> {
      state = {
        isLoading: false,
      };
      setLoading = (isLoading: boolean) => {
        this.setState({ isLoading });
      };
      render(): ReactElement {
        return (
          <>
            <WrappedComponent {...this.props} setLoading={this.setLoading} />
            {this.state.isLoading ? <OverlayLoader /> : null}
          </>
        );
      }
    };
export default withLoader;
