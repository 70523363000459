import { createSelector } from '@reduxjs/toolkit'

export const SLICE_NAME = 'protections'

const select = (state: any) => state[SLICE_NAME]

export const getProtectionData = (state: any) => {
  return state[SLICE_NAME].protections;
}


