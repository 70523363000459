import { AxiosError } from 'axios';
import JoseCryptoSubtle from '@am92/jose-crypto-subtle';
import { WebHttpError } from '../WebHttpError';
import { WEB_HTTP_CONTEXT } from '../CONSTANTS';
import { MISSING_PUBLIC_KEY_ERROR } from '../CONSTANTS/ERRORS';
/** @ignore */
const CryptoInterceptor = {
    request: [requestSuccess],
    response: [responseSuccess]
};
export default CryptoInterceptor;
/** @ignore */
async function requestSuccess(config) {
    const axiosRetry = config['axios-retry'];
    if (axiosRetry) {
        return config;
    }
    const { webHttpContext, webHttpConfig, data } = config;
    if (webHttpConfig.disableCrypto) {
        return config;
    }
    // Use the keys passed
    let encryptionKey = webHttpConfig.encryptionKey;
    let encryptedEncryptionKey = webHttpConfig.encryptedEncryptionKey;
    if (!encryptionKey || !encryptedEncryptionKey) {
        // Need Public Key for generating new keys
        const publicKey = webHttpContext.get(WEB_HTTP_CONTEXT.PUBLIC_KEY);
        if (!publicKey) {
            throw new WebHttpError(undefined, MISSING_PUBLIC_KEY_ERROR);
        }
        // Generate and Manage Keys
        const keys = await JoseCryptoSubtle.generateAndWrapKey(publicKey);
        encryptionKey = keys.encryptionKey;
        encryptedEncryptionKey = keys.encryptedEncryptionKey;
    }
    config.webHttpConfig.encryptionKey = encryptionKey;
    config.webHttpConfig.encryptedEncryptionKey = encryptedEncryptionKey;
    // Encrypt Body
    if (data) {
        const payload = await JoseCryptoSubtle.encryptData(data, encryptionKey);
        config.data = { payload };
    }
    // Return Config
    return config;
}
/** @ignore */
async function responseSuccess(response) {
    const { config, data: body } = response;
    const { webHttpConfig } = config;
    if (webHttpConfig.disableCrypto) {
        return response;
    }
    // Extract Encrypted Data
    const { data } = body;
    if (data) {
        const { payload } = data;
        // Decrypt Data
        const decryptedData = await JoseCryptoSubtle.decryptData(payload, webHttpConfig.encryptionKey);
        response.data = decryptedData;
        handleEncryptedErrorResponse(response);
    }
    return response;
}
/** @ignore */
function handleEncryptedErrorResponse(response) {
    const { data: body, config, request } = response;
    const { statusCode, status, message } = body;
    const { validateStatus } = config;
    const isValid = (validateStatus && validateStatus(statusCode)) || false;
    if (!isValid) {
        const error = new AxiosError(status, statusCode, config, request, response);
        throw error;
    }
}
