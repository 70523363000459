import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiResponseError } from "~/src/Lib/types/api";
import { RemoteData, IRemoteData } from "~/src/Lib/types/dataTransfer";
import {
  NotificationData,
  NotificationType,
  NotificationModel,
} from "./Model";
import groupBy from "lodash/groupBy";

export type NotificationState = {
  notifications: IRemoteData<NotificationModel>;
  notificationsSet: IRemoteData<
    {
      [key in NotificationType]: NotificationData[];
    }
  >;
};
const initialState: NotificationState = {
  notifications: new RemoteData(),
  notificationsSet: new RemoteData(),
};

const NotificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    fetchNotifications(state) {
      state.notifications = state.notifications.setLoading();
    },
    fetchNotificationsSuccess(state, action: PayloadAction<NotificationModel>) {
      const allNotifications = action.payload.notifications.filter((element) => element.headerKey && element.headerKey !== "");
      const keyWisenotifications = groupBy(allNotifications, (data) =>
        data?.headerKey?.toLowerCase()
      );

      state.notificationsSet = state.notificationsSet.setData({
        all: allNotifications || {},
        credit: keyWisenotifications?.credit || {},
        spends: keyWisenotifications?.spends || {},
        investment: keyWisenotifications?.investment || {},
      });
      state.notifications = state.notifications.setData(action.payload);
    },

    fetchNotificationsFail(state, action: PayloadAction<ApiResponseError>) {
      state.notifications = state.notifications.setError(action.payload);
    },
  },
});

export const {
  fetchNotifications,
  fetchNotificationsSuccess,
  fetchNotificationsFail,
} = NotificationSlice.actions;
export const notificationReducer = NotificationSlice.reducer;
