import { ModelMapper } from "~/src/Lib/types/modelmapper";

export interface ActivityDto {
  customerId: string;
}

export interface ActivityModel {
  customerId: string;
}

export class ActivityMapper implements ModelMapper<ActivityDto, ActivityModel> {
  toModel(dto: ActivityDto): ActivityModel {
    return {
      customerId: dto?.customerId || "",
    };
  }
  toDto(model: ActivityModel): ActivityDto {
    throw new Error("Method not implemented. for " + typeof model);
  }
}

export const PAGES = {
  INVESTMENT_DETAILED: "Investment detailed page",
  INSURANCE_DETAILED: "Insurance detailed page",
  SPENDS: "Spends Homepage",
  SPENDS_DETAILED: "View Detailed Spends Page",
  CREDIT_DETAILED: "Credit score detailed page",
  CREDIT_SCORE_PAGE: "Credit Score Page",
  CREDIT_LINE_PAGE: "Credit line Page",
  HOME: "Homepage",
  NETWORTH: "Networth",
  CARD_DETAILS_IN_CREDIT_LINE_PAGE: "Card Details in Credit Line Page",
  LOAN_DETAILS_IN_CREDIT_LINE_PAGE: "Loan Details in Credit Line Page",
};
export const ACTIONS: any = {
  LOAD: "Load",
  MF_SSO: "MF_SSO",
  START_MF_NOW: "Start MF Now SSO",
  START_PPF_NOW: "Start PPF Now SSO",
  START_SIP: "Start SIP Now SSO",
  PPF_SSO: "PPF_SSO",
  Deposits_SSO: "DEPOSIT_SSO",
  NPS_SSO: "NPS_SSO",
  DIGIGOLD_SSO: "DIGIGOLD_SSO",
  SGB_SSO: "SGB_SSO",
  FRSB_SSO: "FRSB_SSO",
  HEALTH_INSR_SSO: "Health Insurance SSO",
  LIFE_INSR_SSO: "Life Insurance SSO",
  SPENDS_GRAB_DEALS: "Grabdeals_SSO",
  INVESTMENT_SPENDS: "Investment_Spends_SSO",
  SPENDS_HEALTHCARE: "Healthcare_Spends_SSO",
  VIEW_DEBIT_SPENDS: "View Debit Card Spends",
  VIEW_CREDIT_SPENDS: "View Credit Card Spends",
  VIEW_UPI_SPENDS: "View Upi Spends",
  VIEW_ALL_SPENDS: "View All Spends",
  VIEW_CAT_SUBCAT_SPENDS: "View Category-Subcategory Spends",
  SPEND_SETTING_LIMIT: "Limit Setting For",
  SPEND_FILTER: "Using Filter For",
  VIEW_DETAIL_SPENDS: "Landing on Category/SubCategory wise spends",
  CREDIT_SCORE_BANNER: "Credit_Score_Banner",
  WHERW_YOU_STAND: "Where_You_Stand",
  VIEW_DETAIL_LOAN_NUDGE: "View_Details_Loan",
  VIEW_DETAIL_CC_NUDGE: "View_Details_CC",
  EXPLORE_LOAN_SUL: "Explore_Loans",
  LOAN_DETAIL_CREDIT_LINE: "Loan_Details",
  CC_DETAIL_CREDIT_LINE: "CC_Details",
  EXPLORE_LOANS: "Explore_Loans",
  APPLY_NOW_CC: "Apply_Now_CC",
  PAY_NOW_CC: "Pay_Now_CC",
  PPF_VIEW_DETAILS: "PPF_VIEW_DETAILS",
  PPF_EXPLORE: "PPF_EXPLORE",
  DIGIGOLD_EXPLORE: "DIGIGOLD_EXPLORE",
  DIGIGOLD_VIEW_DETAILS: "DIGIGOLD_VIEW_DETAILS",
  DIGIGOLD_START_SIP: "DG_START_SIP",
  Deposits_VIEW_DETAILS: "DEPOSIT_VIEW_DETAILS",
  Deposits_EXPLORE: "DEPOSIT_EXPLORE",
  MF_VIEW_DETAILS: "MF_VIEW_DETAILS",
  MF_EXPLORE: "MF_EXPLORE",
  MF_START_SIP: "MF_START_SIP",
  SGB_VIEW_DETAILS: "SGB_VIEW_DETAILS",
  SGB_EXPLORE: "SGB_EXPLORE",
  FRSB_VIEW_DETAILS: "FRSB_VIEW_DETAILS",
  FRSB_EXPLORE: "FRSB_EXPLORE",
  NPS_EXPLORE: "NPS_EXPLORE",
  NPS_VIEW_DETAILS: "NPS_VIEW_DETAILS",
  CREDIT_DISCLAIMER_OPEN: "CREDIT_DISCLAIMER_OPEN",
  CREDIT_ACCEPTED_DISCLAIMER: "CREDIT_ACCEPTED_DISCLAIMER",
  MULTIBANK_SSO: 'MULTIBANK_SSO'
};


export const ERROR_MSG = 'SOMETHING WENT WRONG'
