import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "~/src/Configurations/AppStore";

export const SLICE_NAME = "sso";

const getSSOData = (state: any) => state[SLICE_NAME];

export const getSSO = createSelector(getSSOData, (journey) => {
  return journey;
});


