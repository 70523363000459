import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  dateDiffInDays,
  getGoldAmount,
  getUpto2Decimal,
  round,
} from "~/src/Lib/utils";
import { ApiResponseError } from "~/src/Lib/types/api";

import { RemoteData, IRemoteData } from "~/src/Lib/types/dataTransfer";
import {
  InvestmentsModel,
  MutualFundsModel,
  TotalInvestmentsInfo,
  FDRDModel,
  PPFModel,
  NPSModel,
  InvestmentTypeENUM,
  GoldModel,
} from "./Model";
import { SLICE_NAME } from "./Selectors";

export type InvestmentState = {
  investments: IRemoteData<InvestmentsModel>;
  totalInvestments: IRemoteData<TotalInvestmentsInfo>;
  investmentsOrder?: string[];
};
const initialState: InvestmentState = {
  investments: new RemoteData(),
  totalInvestments: new RemoteData(),
};
export type MFState = {
  mutualFunds: IRemoteData<MutualFundsModel>;
};
const initialMFState: MFState = {
  mutualFunds: new RemoteData(),
};
export type GoldState = {
  gold: IRemoteData<GoldModel>;
};
const initialGoldState: GoldState = {
  gold: new RemoteData(),
};
export type FDRDState = {
  fdrd: IRemoteData<FDRDModel>;
};
const initialFDRDState: FDRDState = {
  fdrd: new RemoteData(),
};
export type PPFState = {
  ppf: IRemoteData<PPFModel>;
};
const initialPPFState: PPFState = {
  ppf: new RemoteData(),
};
export type NPSState = {
  nps: IRemoteData<NPSModel>;
};
const initialNPSState: NPSState = {
  nps: new RemoteData(),
};
const InvestmentSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    fetchInvestments(state) {
      state.investments = state.investments.setLoading();
    },
    fetchInvestmentsSuccess(state, action: PayloadAction<InvestmentsModel>) {
      state.investments = state.investments.setData(action.payload);
      state.totalInvestments.data = calculatePercentage(state);
      state.investmentsOrder = getInvestmentsSortOrder(
        state?.investments?.data?.order || []
      );
    },
    fetchInvestmentsFail(state, action: PayloadAction<ApiResponseError>) {
      state.investments = state.investments.setError(action.payload);
    },
    updateDigiGold(state, action: PayloadAction<GoldModel>) {
      if (state?.investments?.data) {
        state.investments.data.DIGIGOLD.gold = action.payload;
        (state.investments.data.totalInvested = round(
          Number.parseFloat(
            (
              Number(
                state.investments?.data?.totalInvestedAmountForAllProducts
                - (!state.investments?.data?.STOCKS?.hasConsent && state.investments?.data?.STOCKS?.isActive === 1 ? (state.investments?.data?.STOCKS?.aggregates?.currentValue) : 0)
              ) + getGoldAmount(state.investments?.data?.DIGIGOLD) || 0
            ).toString()
          ),
          2
        )),
          (state.investments.data.DIGIGOLD.total = round(
            getGoldAmount(state.investments.data.DIGIGOLD),
            2
          ));
      }
      if (state?.totalInvestments?.data) {
        state.totalInvestments.data.digitalGold =
          round(
            getUpto2Decimal(
              ((state?.investments?.data?.DIGIGOLD?.total || 0) /
                (state?.investments?.data?.totalInvested || 1)) *
              100 || 0
            ),
            2
          ) || 0;
      }
      state.totalInvestments.data = calculatePercentage(state);
      return { ...state };
    },
  },
});

const MFSlice = createSlice({
  name: "mutualFunds",
  initialState: initialMFState,
  reducers: {
    fetchMutualFunds(state) {
      state.mutualFunds = state.mutualFunds.setLoading();
    },
    fetchMutualFundsSuccess(state, action: PayloadAction<MutualFundsModel>) {
      state.mutualFunds = state.mutualFunds.setData(action.payload);
    },
    fetchMutualFundsFail(state, action: PayloadAction<ApiResponseError>) {
      state.mutualFunds = state.mutualFunds.setError(action.payload);
    },
  },
});

const FDRDSlice = createSlice({
  name: "fdrd",
  initialState: initialFDRDState,
  reducers: {
    fetchFDRD(state) {
      state.fdrd = state.fdrd.setLoading();
    },
    fetchFDRDSuccess(state, action: PayloadAction<FDRDModel>) {
      state.fdrd = state.fdrd.setData(action.payload);
    },
    fetchFDRDFail(state, action: PayloadAction<ApiResponseError>) {
      state.fdrd = state.fdrd.setError(action.payload);
    },
  },
});

const PPFSlice = createSlice({
  name: "ppf",
  initialState: initialPPFState,
  reducers: {
    fetchPPF(state) {
      state.ppf = state.ppf.setLoading();
    },
    fetchPPFSuccess(state, action: PayloadAction<PPFModel>) {
      state.ppf = state.ppf.setData(action.payload);
    },
    fetchPPFFail(state, action: PayloadAction<ApiResponseError>) {
      state.ppf = state.ppf.setError(action.payload);
    },
  },
});

const NPSSlice = createSlice({
  name: "nps",
  initialState: initialNPSState,
  reducers: {
    fetchNPS(state) {
      state.nps = state.nps.setLoading();
    },
    fetchNPSSuccess(state, action: PayloadAction<NPSModel>) {
      state.nps = state.nps.setData(action.payload);
    },
    fetchNPSFail(state, action: PayloadAction<ApiResponseError>) {
      state.nps = state.nps.setError(action.payload);
    },
  },
});
const GoldSlice = createSlice({
  name: "gold",
  initialState: initialGoldState,
  reducers: {
    fetchGoldData(state) {
      state.gold = state.gold.setLoading();
    },
    fetchGoldDataSuccess(state, action: PayloadAction<GoldModel>) {
      state.gold = state.gold.setData(action.payload);
    },
    fetchGoldDataFail(state, action: PayloadAction<ApiResponseError>) {
      state.gold = state.gold.setError(action.payload);
    },
  },
});

export const {
  fetchInvestments,
  fetchInvestmentsSuccess,
  fetchInvestmentsFail,
  updateDigiGold,
} = InvestmentSlice.actions;

export const {
  fetchMutualFunds,
  fetchMutualFundsSuccess,
  fetchMutualFundsFail,
} = MFSlice.actions;

export const { fetchGoldData, fetchGoldDataSuccess, fetchGoldDataFail } =
  GoldSlice.actions;

export const { fetchFDRD, fetchFDRDSuccess, fetchFDRDFail } = FDRDSlice.actions;

export const { fetchPPF, fetchPPFSuccess, fetchPPFFail } = PPFSlice.actions;

export const { fetchNPS, fetchNPSSuccess, fetchNPSFail } = NPSSlice.actions;

export const investmentReducer = InvestmentSlice.reducer;
export const mutualFundsReducer = MFSlice.reducer;
export const fdrdReducer = FDRDSlice.reducer;
export const ppfReducer = PPFSlice.reducer;
export const npsReducer = NPSSlice.reducer;
export const goldReducer = GoldSlice.reducer;

type InvestmentTypeExceptDep = Exclude<
  InvestmentTypeENUM,
  | InvestmentTypeENUM.FD
  | InvestmentTypeENUM.RD
  | InvestmentTypeENUM.Deposits
  | InvestmentTypeENUM.DIGIGOLD
  | InvestmentTypeENUM.FRSB
  | InvestmentTypeENUM.STOCKS
>;

export const getInvestmentsSortOrder = (data: string[]): string[] => {
  const investmentsArray: { [key: string]: string } = {
    deposits: "Deposits",
    mutualFund: "MF",
    digitalGold: "DIGIGOLD",
    frsb: "FRSB",
    publicProvidentFund: "PPF",
    nps: "NPS",
    sgb: "SGB",
    demat: 'STOCKS'
  };

  const investmentsOrder = [] as string[];
  data.forEach((item) => {
    const value = investmentsArray[item];
    if (value) {
      investmentsOrder.push(value);
    }
  });
  return investmentsOrder;
};

// export const getInvestmentsSortOrder = (
//   data: InvestmentsModel,
//   fromSGBTranche: string,
//   toSGBTranche: string,
//   deltaDays: number
// ): string[] => {
//   const isBetweenTranche =
//     dateDiffInDays(new Date(), new Date(fromSGBTranche || "")) <= 0 &&
//     dateDiffInDays(new Date(), new Date(toSGBTranche || "")) >= 0;
//   const todaysDate = new Date();
//   const firstDay = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), 1);
//   const lastDay = new Date();
//   lastDay.setDate(firstDay.getDate() + deltaDays - 1);
//   const isBetweenSpecialPeriod =
//     dateDiffInDays(new Date(), firstDay) <= 0 &&
//     dateDiffInDays(new Date(), lastDay) >= 0;

//   const investmentsPoints = {
//     Deposits: 14,
//     MF: 12,
//     DIGIGOLD: 10,
//     FRSB: 6,
//     PPF: 8,
//     NPS: 4,
//     SGB: 2,
//     // STOCKS: 1,
//   };
//   Object.keys(investmentsPoints).forEach((investType: string) => {
//     if (data[investType as InvestmentTypeExceptDep]?.total > 0 && data[investType as InvestmentTypeExceptDep]?.isActive)
//       investmentsPoints[investType as InvestmentTypeExceptDep] += 14;
//   });
//   investmentsPoints.SGB = isBetweenTranche
//     ? investmentsPoints.SGB + 30
//     : investmentsPoints.SGB;
//   investmentsPoints.PPF = isBetweenSpecialPeriod
//     ? investmentsPoints.PPF + 5
//     : investmentsPoints.PPF;
//   // if (process.env.NODE_ENV !== "production") {
//   //   console.table(investmentsPoints);
//   // }
//   return Object.keys(investmentsPoints).sort(
//     (a, b) =>
//       investmentsPoints[b as InvestmentTypeExceptDep] -
//       investmentsPoints[a as InvestmentTypeExceptDep]
//   );
// };

export const calculatePercentage = (state: typeof initialState) => {
  state.totalInvestments.data = {
    total: state?.investments?.data?.totalInvested || 0,
    mutualFunds:
      (state?.investments.data?.MF?.isActive &&
        round(
          getUpto2Decimal(
            ((state?.investments?.data?.MF?.total || 0) /
              (state?.investments?.data?.totalInvested || 1)) *
            100 || 0
          ),
          2
        )) ||
      0,
    FDRD:
      ((state?.investments.data?.FD?.isActive ||
        state?.investments.data?.RD?.isActive) &&
        round(
          getUpto2Decimal(
            (((state?.investments?.data?.FD?.total || 0) +
              (state?.investments?.data?.RD?.total || 0)) /
              (state?.investments?.data?.totalInvested || 1)) *
            100 || 0
          ),
          2
        )) ||
      0,
    ppf:
      (state?.investments.data?.PPF?.isActive &&
        round(
          getUpto2Decimal(
            ((state?.investments.data?.PPF?.total || 0) /
              (state?.investments.data?.totalInvested || 1)) *
            100 || 0
          ),
          2
        )) ||
      0,
    nps:
      (state?.investments.data?.NPS?.isActive &&
        round(
          getUpto2Decimal(
            ((state?.investments?.data?.NPS?.total || 0) /
              (state?.investments?.data?.totalInvested || 1)) *
            100 || 0
          ),
          2
        )) ||
      0,
    digitalGold:
      round(
        getUpto2Decimal(
          ((state?.investments?.data?.DIGIGOLD?.total || 0) /
            (state?.investments?.data?.totalInvested || 1)) *
          100 || 0
        ),
        2
      ) || 0,
    frsb:
      round(
        getUpto2Decimal(
          ((state?.investments?.data?.FRSB?.total || 0) /
            (state?.investments?.data?.totalInvested || 1)) *
          100 || 0
        ),
        2
      ) || 0,
    stocks:
      round(
        getUpto2Decimal(
          ((state?.investments?.data?.STOCKS?.aggregates?.currentValue || 0) /
            (state?.investments?.data?.totalInvested || 1)) *
          100 || 0
        ),
        2
      ) || 0,
    FDRDInvestedAmount: state?.investments?.data?.Deposits?.total || 0,
    MFInvestedAmount: state?.investments?.data?.MF?.total || 0,
    PPFInvestedAmount: state?.investments?.data?.PPF?.total || 0,
    NPSInvestedAmount: state?.investments?.data?.NPS?.total || 0,
    FRSBInvestedAmount: state?.investments?.data?.FRSB?.total || 0,
    STOCKSInvestedAmount:
      state?.investments?.data?.STOCKS?.aggregates?.investedValue || 0,
    isInvested:
      (state?.investments?.data?.totalInvested || 0) > 0 ? true : false,
  };
  return { ...state.totalInvestments.data };
};

export default InvestmentSlice.reducer;
