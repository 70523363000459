import { ActionReducerMapBuilder, createSlice, CreateSliceOptions } from "@reduxjs/toolkit";
import { RemoteData, IRemoteData } from "~/src/Lib/types/dataTransfer";
import {
  NetworthDataModel,
  NetworthInvestmentsModel,
  NetworthModel,
} from "./Model";

import { SLICE_NAME } from "./Selectors";
import { deleteNetworthDataServiceTraceActions, getNetworthGoldDataServiceTraceActions, getNetworthServiceTraceActions, setNetworthDataServiceTraceActions } from "./Actions";
import { dateDiffInDays, getGoldAmount } from "~/src/Lib/utils";
import { InvestmentTypeExceptDep } from "../Investment/Model";
import { round } from "lodash";

export const initialState = {
  networth: {},
  networthdata: {},
  investmentsOrder: [],
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      getNetworthServiceTraceActions.loading,
      (state, { payload }: any) => {
        state.networth = payload;
      }
    );
    builder.addCase(
      getNetworthServiceTraceActions.success,
      (state, { payload }: any) => {
        state.networth = payload;
        state.investmentsOrder = getInvestmentsSortOrder(
          state?.networth?.investments?.order
        );
      }
    );
    builder.addCase(
      getNetworthGoldDataServiceTraceActions.success,
      (state, { payload }: any) => {
        if (state?.networth?.investments) {
          state.networth.investments.DIGIGOLD.gold = payload;
          state.networth.investments.totalInvested = round(
            Number.parseFloat(
              ((Number(state?.networth?.investments?.totalInvestedAmountForAllProducts)) +
                getGoldAmount(state?.networth?.investments?.DIGIGOLD) || 0).toString()
            ),
            2
          ),
            state.networth.investments.DIGIGOLD.total = round(
              getGoldAmount(state?.networth?.investments?.DIGIGOLD),
              1
            );
          state.networth.totalNetworthBalance = state.networth.totalBalance + state.networth.investments.totalInvested
          state.networth.totalAssetsBalance = state.networth.totalAssets + state.networth.investments.DIGIGOLD.total
        }

      }
    );
    builder.addCase(
      getNetworthServiceTraceActions.error,
      (state, { payload }: any) => {
        state.networth = payload;
      }
    );
    builder.addCase(
      setNetworthDataServiceTraceActions.success,
      (state, { payload }: any) => {
        state.networthdata = payload;
      }
    );
    builder.addCase(
      setNetworthDataServiceTraceActions.loading,
      (state, { payload }: any) => {
        state.networthdata = payload;
      }
    );
    builder.addCase(
      setNetworthDataServiceTraceActions.error,
      (state, { payload }: any) => {
        state.networthdata = payload;
      }
    );
    builder.addCase(
      deleteNetworthDataServiceTraceActions.success,
      (state, { payload }: any) => {
        state.networthdata = payload;
      }
    );
    builder.addCase(
      deleteNetworthDataServiceTraceActions.loading,
      (state, { payload }: any) => {
        state.networthdata = payload;
      }
    );
    builder.addCase(
      deleteNetworthDataServiceTraceActions.error,
      (state, { payload }: any) => {
        state.networthdata = payload;
      }
    );
  },
};

const NetworthSlice = createSlice(sliceOptions);
export const NetworthReducer = NetworthSlice.reducer;


export const getInvestmentsSortOrder = (
  data: string[],
): string[] => {

  const investmentsArray: { [key: string]: string } = {
    'deposits': 'Deposits',
    'mutualFund': 'MF',
    'digitalGold': 'DIGIGOLD',
    'frsb': 'FRSB',
    'publicProvidentFund': 'PPF',
    'nps': 'NPS',
    'sgb': 'SGB',
    'demat': 'STOCKS'
  };

  const investmentsOrder = [] as string[];
  data.forEach(item => {
    const value = investmentsArray[item];
    if (value) {
      investmentsOrder.push(value);
    }
  });
  return investmentsOrder;

};

