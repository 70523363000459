import {
  AvgHighSpendsType,
  SpendMiscData,
} from "~/src/Redux/SpendsAnalyzer/Reducer";
import { DIGIGOLDModel } from "../Redux/Investment/Model";
import { NotificationData } from "../Redux/Notifications/Model";
import { useEffect, useRef } from "react";

// import { NotificationData } from "feature/Notification/Notification.model";
// import { DIGIGOLDModel } from "feature/Investment/Investment.model";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const round = (value: number, precision: number) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const showCustomersinLacs = (value: number) => {
  if (value === 0) return "No";
  return (
    (value !== Math.round(value) ? "Over " : "Over ") +
    Math.floor(value) +
    " lakh"
  );
};

export const numberWithCommas = (value: number) => {
  return value.toLocaleString("en-IN");
};

export const dateDiffInDays = (a: Date, b: Date) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export const capitalizeString = (str: string) => {
  return str?.charAt(0)?.toUpperCase() + str?.toLocaleLowerCase()?.slice(1);
};

export const capitalizeFirstLetterString = (str: string) => {
  const words = str.split(" ");
  return words
    .map((word) => {
      return word === "SIP"
        ? word
        : word[0].toUpperCase() + word?.toLocaleLowerCase()?.slice(1);
    })
    .join(" ");
};

export const roundMultiplier = (value: number, multiplier: number) => {
  return Math.floor(value / multiplier) * multiplier;
};

export const createInputField = (field: string, data: string) => {
  const hiddenField = document.createElement("input");
  hiddenField.setAttribute("id", field);
  // hiddenField.setAttribute("for", field);
  hiddenField.setAttribute("name", field);
  hiddenField.setAttribute("value", data);
  return hiddenField;
};

export const formSubmission = (data: {
  url: string;
  ID?: string;
  customerId?: string;
  channel?: string;
  source?: string;
  data?: string;
  i?: string;
  encryptionType?: string;
  authToken?: string;
  key?: string;
  channelId?: string;
}) => {
  const form = document.createElement("form");
  form.style.display = "none";
  form.setAttribute("method", "POST");
  form.setAttribute("action", data?.url);
  form.enctype = data?.encryptionType ?? "multipart/form-data";
  form.setAttribute("target", "_self");
  const inputElementsArray = [];
  data?.ID && inputElementsArray.push(createInputField("ID", data?.ID));
  data?.channel &&
    inputElementsArray.push(createInputField("channel", data?.channel));
  data?.customerId &&
    inputElementsArray.push(createInputField("customer_id", data?.customerId));
  data?.authToken &&
    inputElementsArray.push(createInputField("authToken", data?.authToken));
  data?.data && inputElementsArray.push(createInputField("data", data?.data));
  data?.key && inputElementsArray.push(createInputField("key", data?.key));
  data?.channelId &&
    inputElementsArray.push(createInputField("channelId", data?.channelId));
  data?.source &&
    inputElementsArray.push(createInputField("source", data?.source));

  data?.i && inputElementsArray.push(createInputField("i", data?.i));
  inputElementsArray.forEach((element) => {
    return element !== null && form.appendChild(element);
  });

  document.body.appendChild(form);
  form.submit();
};

export const getNumberValue = (value: string): string => {
  const regexPattern = /^[0-9\b]+$/;
  if (value === "" || regexPattern.test(value)) {
    return value;
  }
  return "";
};

export const getLastThreeMonths = (currentMonth: number): string[] => {
  const initialMonth =
    currentMonth - 2 > 0 ? currentMonth - 2 : currentMonth + 10;
  const lastThreeMonths = [];
  for (let i = initialMonth; i < initialMonth + 3; i++) {
    if (i > 12)
      lastThreeMonths.push((i - 12).toString());
    else
      lastThreeMonths.push(i.toString());
  }
  return lastThreeMonths;
};

export const getMonths = (currentMonth: number, numberOfMonths: number): string[] => {
  // const initialMonth =
  //   currentMonth - (numberOfMonths - 1) > 0 ? currentMonth - (numberOfMonths - 1) : currentMonth + 10;
  const months = [];
  // for (let i = initialMonth; i < initialMonth + numberOfMonths; i++) {
  //   if (i > 12)
  //     months.push((convertToIndex(i.toString()) + 1).toString());
  //   else
  //     months.push(i.toString());
  // }
  for (let i = currentMonth - numberOfMonths + 1; i <= currentMonth; i++) {
    const adjustedMonth = i <= 0 ? 12 + i : i > 12 ? i - 12 : i;
    months.push(adjustedMonth.toString());
  }
  return months;
};

export const convertToIndex = (idx: string): number => {
  return (parseInt(idx, 10) % 12 === 0 ? 12 : parseInt(idx, 10) % 12) - 1;
};

export const getCurrentMonth = (dayDifference: number): string => {
  const date = new URLSearchParams(location?.search)?.get("date") as string;
  const todaysDate = date ? new Date(date) : new Date();
  todaysDate.setDate(todaysDate.getDate() - dayDifference);
  return new Date(todaysDate).toLocaleString("default", {
    month: "numeric",
  });
};

export const convertToAmount = (amount = 0): string => {
  amount = parseFloat(amount?.toString());
  const result = `${amount?.toLocaleString("en-in", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 2,
  })}`;
  if (result) {
    const [a, b] = result.split(".");
    if (b === "00") {
      return a;
    }
  }
  return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reduceData = (data: any, val: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return data.reduce((prev: string, curr: any, i: number) => {
    if (i === 0) return capitalizeString(curr[val]);
    if (i + 1 === data.length) {
      return prev + ` and ${capitalizeString(curr[val])}`;
    }
    return prev + `, ${capitalizeString(curr[val])}`;
  }, "");
};

export const convertArraytoSentence = (categories: SpendMiscData[]): string => {
  return reduceData(categories, "category");
};

export const mergedSpendLimitData = (
  categories: NotificationData[]
): string => {
  return reduceData(categories, "highlight");
};

export const isSessionStorage = (key: string): boolean => {
  return (
    window.sessionStorage.length > 0 && sessionStorage.getItem(key) != null
  );
};

export const setDefaultValue = (key: string): number => {
  if (isNaN(parseInt(key))) return -1;
  return round(parseFloat(key), 2);
};

export const setAmountForFDRD = (
  fdAmount: number,
  rdAmount: number
): number => {
  return fdAmount >= 0 && rdAmount >= 0 ? round(fdAmount + rdAmount, 1) : -1;
};

// export const isWithinLastSixMonths = (
//   currentMonth: number,
//   otherMonth: number
// ): boolean => {
//   const initialMonth =
//     currentMonth - 5 > 0 ? currentMonth - 5 : currentMonth + 7;
//   const lastMonth = initialMonth + 4;
//   otherMonth =
//     currentMonth - 5 <= 0
//       ? otherMonth < 6
//         ? otherMonth + 12
//         : otherMonth
//       : otherMonth;
//   return otherMonth >= initialMonth && otherMonth <= lastMonth;
// };

export let isNotifications = false;
export const changeNotificationStatus = (value: boolean) => {
  isNotifications = value;
};

export type gaugeObject = {
  color: string;
  status: string;
};
export const getGaugeObject = (score: number): gaugeObject => {
  if (score >= 800) return { color: "#1FC24E", status: "Excellent" };
  if (score >= 700) return { color: "#FEC600", status: "Good" };
  if (score >= 600) return { color: "#F17F22", status: "Average" };
  return { color: "#FF2121", status: "Poor" };
};

export const mergeCategoriesIntoOne = (
  categories: AvgHighSpendsType
): string => {
  return categories.reduce((prev: string, curr, i) => {
    if (i === 0) return curr.name;
    if (i + 1 === categories.length) {
      return prev + ` and ${curr.name}`;
    }
    return prev + `, ${curr.name}`;
  }, "");
};

export const getCardLastDigits = (cardNumber: string): string => {
  return cardNumber
    ? cardNumber.slice(cardNumber.length - 4, cardNumber.length)
    : "";
};

const truncateByDecimalPlace = (
  value: number,
  numDecimalPlaces: number
): number => {
  return (
    Math.trunc(value * Math.pow(10, numDecimalPlaces)) /
    Math.pow(10, numDecimalPlaces)
  );
};

export const convertAmountIntoFraction = (amount: number): string => {
  if (amount < 100000)
    return `${truncateByDecimalPlace(amount / 1000, 2)} Thousand`;
  return `${truncateByDecimalPlace(amount / 100000, 2)} Lakh`;
};

export const convertToOrdinal = (d: number): string => {
  if (d > 3 && d < 21) return `${d}th`;
  switch (d % 10) {
    case 1:
      return `${d}st`;
    case 2:
      return `${d}nd`;
    case 3:
      return `${d}rd`;
    default:
      return `${d}th`;
  }
};

export const convertDateToStringDate = (date: string): string => {
  if (!date) return "";
  const currentDate = new Date(
    date.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
  );
  const options = { month: "short" };
  return `${convertToOrdinal(
    currentDate.getDate()
  )} ${currentDate.toLocaleDateString(
    "en-IN",
    options as any
  )}, ${currentDate.getFullYear()}`;
};

export const convertNumberIntoInrAmount = (amount: number): string => {
  var isNegative = amount < 0;

  let formattedAmount = `${amount}`;
  amount = Math.abs(amount);

  if (amount < 1000) formattedAmount = `${amount}`;
  else if (amount < 100000) formattedAmount = `${round(amount / 1000, 2)}K`;
  else if (amount < 10000000) formattedAmount = `${round(amount / 100000, 2)}L`;
  else if (amount >= 10000000)
    formattedAmount = `${`${round(amount / 10000000, 2)}CR`}`;

  if (isNegative) {
    formattedAmount = "-" + formattedAmount;
  }

  return formattedAmount;
};

export const truncateText = (input: string, len: number) => {
  if (input.length > len) {
    return input.substring(0, len) + "...";
  }
  return input;
};

export const isNotNumber = (data: number | null) => {
  return data == null || Number.isNaN(data);
};

export const getUpto2Decimal = (data: number) => {
  if (data < 0) return data;
  if (data < 0.01 && data !== 0) {
    return 0.01;
  }
  return data;
};
export const getformatedDate = (date: string) => {
  const today = new Date(date);
  const yyyy = today.getFullYear();
  const mm = today.getMonth() + 1; // Months start at 0!
  const dd = today.getDate();
  let dtst = dd + "";
  let mmst = mm + "";
  if (dd < 10) dtst = "0" + dd;
  if (mm < 10) mmst = "0" + mm;
  const formattedToday = dtst + "/" + mmst + "/" + yyyy;
  return formattedToday;
};

export const getDateDiffInMin = (pastDate?: string) => {
  const pastTime = pastDate ? pastDate : sessionStorage.getItem("ct") || null;
  const startTime = pastTime ? new Date(pastTime) : new Date();
  const endTime = new Date();
  const difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
  const resultInMinutes = Math.round(difference / 60000);
  return resultInMinutes;
};

export const getGoldAmount = (data: DIGIGOLDModel): number => {
  if (!data) return 0;
  const { balance, gold } = data;
  if (balance && gold) {
    const {
      sell: { price },
    } = gold;
    return Number(balance) * price || 0;
  }
  return 0;
};

export const getLastNMonthsDates = (noOfMonths:number) => {
  const today = new Date();
  let lastSixMonths = []

  for (var i = noOfMonths; i > 0; i -= 1) {
    const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
    lastSixMonths.push(date)
  }
  return lastSixMonths
}


export const getCurrentMonthStartDate = () => {
  const now = new Date();

  const year = now.getFullYear();
  const month = now.getMonth();

  const firstDayOfMonth = new Date(year, month, 1);

  const formattedYear = firstDayOfMonth.getFullYear();
  const formattedMonth = String(firstDayOfMonth.getMonth() + 1).padStart(2, '0');
  const formattedDay = String(firstDayOfMonth.getDate()).padStart(2, '0');
  const formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}`;
  return formattedDate
}

export const getCommaSeperatedNumber = (value: string) => {
  const numericValue = (value).toString().replace(/\D/g, '');
  let lastThree = numericValue.toString().slice(-3);
  let otherNumbers = numericValue.toString().slice(0, -3);
  if (otherNumbers !== '') lastThree = ',' + lastThree;
  const formattedInteger = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;
  return formattedInteger
}
